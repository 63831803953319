@import url("https://fonts.googleapis.com/css?family=Poppins:600|Work+Sans:400,500");

html {
  font-size: 16px;
  font-size: 62.5%;
  font-family: "Work Sans", sans-serif;
  background: #7BA7C5;
}

html.overflow {
  overflow: hidden;
}

body {
  font-size: 1.7rem;
  font-family: "Work Sans", sans-serif;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

body.overflow {
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  body {
    font-size: 1.6rem;
    position: relative;
    left: 0;
  }

  body.overflow:not(.course) {
    left: -85vw;
  }
}

h1,
h2.large,
h2,
.cart-item .course-price .price,
.purchase-card .price,
.contact-list-boxed li,
.share p,
header .details .info p,
h3,
.purchase-card .users,
.exam-info .info .value,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #3A3C48;
}

h1,
h2.large,
.cart-item .course-price .large.price,
.purchase-card .large.price,
.contact-list-boxed li.large,
.share p.large,
header .details .info p.large {
  font-size: 3.9rem;
  line-height: 4.7rem;
}

@media only screen and (max-width: 991px) {
  h1,
  h2.large,
  .cart-item .course-price .large.price,
  .purchase-card .large.price,
  .contact-list-boxed li.large,
  .share p.large,
  header .details .info p.large {
    font-size: 2.5rem;
    line-height: 3.3rem;
  }
}

h2,
.cart-item .course-price .price,
.purchase-card .price,
.contact-list-boxed li,
.share p,
header .details .info p {
  font-size: 2.8rem;
  line-height: 3.6rem;
}

@media only screen and (max-width: 991px) {
  h2,
  .cart-item .course-price .price,
  .purchase-card .price,
  .contact-list-boxed li,
  .share p,
  header .details .info p {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

h3,
.purchase-card .users,
.exam-info .info .value {
  font-size: 1.9rem;
  line-height: 2.7rem;
}

@media only screen and (max-width: 991px) {
  h3,
  .purchase-card .users,
  .exam-info .info .value {
    font-size: 1.9rem;
    line-height: 2.7rem;
  }
}

h4 {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.5rem;
}

h5 {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
}

a {
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
  opacity: 1;
  color: #992945;
}

a:hover {
  text-decoration: none;
  color: #6A162B;
}

address p {
  margin-bottom: 0;
}

* {
  -webkit-overflow-scrolling: touch;
}

.p-6 {
  padding: 5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

.p-7 {
  padding: 10rem !important;
}

.pt-7,
.py-7 {
  padding-top: 10rem !important;
}

.pr-7,
.px-7 {
  padding-right: 10rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 10rem !important;
}

.pl-7,
.px-7 {
  padding-left: 10rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 5rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -5rem !important;
}

.m-7 {
  margin: 10rem !important;
}

.mt-7,
.my-7 {
  margin-top: 10rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 10rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 10rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 10rem !important;
}

.m-n7 {
  margin: -10rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -10rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -10rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -10rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -10rem !important;
}

@media (min-width: 576px) {
  .m-sm-6 {
    margin: 5rem !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important;
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important;
  }

  .m-sm-7 {
    margin: 10rem !important;
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 10rem !important;
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 10rem !important;
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 10rem !important;
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 10rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }

  .p-sm-7 {
    padding: 10rem !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 10rem !important;
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 10rem !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 10rem !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 10rem !important;
  }

  .m-sm-n6 {
    margin: -5rem !important;
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important;
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important;
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important;
  }

  .m-sm-n7 {
    margin: -10rem !important;
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -10rem !important;
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -10rem !important;
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -10rem !important;
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -10rem !important;
  }
}

@media (min-width: 768px) {
  .m-md-6 {
    margin: 5rem !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important;
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important;
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important;
  }

  .m-md-7 {
    margin: 10rem !important;
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 10rem !important;
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 10rem !important;
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 10rem !important;
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 10rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }

  .p-md-7 {
    padding: 10rem !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 10rem !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 10rem !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 10rem !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 10rem !important;
  }

  .m-md-n6 {
    margin: -5rem !important;
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important;
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important;
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important;
  }

  .m-md-n7 {
    margin: -10rem !important;
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -10rem !important;
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -10rem !important;
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -10rem !important;
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -10rem !important;
  }
}

@media (min-width: 992px) {
  .m-lg-6 {
    margin: 5rem !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important;
  }

  .m-lg-7 {
    margin: 10rem !important;
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 10rem !important;
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 10rem !important;
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 10rem !important;
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 10rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }

  .p-lg-7 {
    padding: 10rem !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 10rem !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 10rem !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 10rem !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 10rem !important;
  }

  .m-lg-n6 {
    margin: -5rem !important;
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important;
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important;
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important;
  }

  .m-lg-n7 {
    margin: -10rem !important;
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -10rem !important;
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -10rem !important;
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -10rem !important;
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -10rem !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-6 {
    margin: 5rem !important;
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important;
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important;
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important;
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important;
  }

  .m-xl-7 {
    margin: 10rem !important;
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 10rem !important;
  }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 10rem !important;
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 10rem !important;
  }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 10rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }

  .p-xl-7 {
    padding: 10rem !important;
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 10rem !important;
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 10rem !important;
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 10rem !important;
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 10rem !important;
  }

  .m-xl-n6 {
    margin: -5rem !important;
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important;
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important;
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important;
  }

  .m-xl-n7 {
    margin: -10rem !important;
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -10rem !important;
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -10rem !important;
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -10rem !important;
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -10rem !important;
  }
}

.white {
  color: white !important;
}

.black {
  color: black !important;
}

.primary {
  color: #3A3C48 !important;
}

.primary-light {
  color: #454856 !important;
}

.primary-dark {
  color: #141622 !important;
}

.secondary {
  color: #992945 !important;
}

.secondary-light {
  color: #ad2e4e !important;
}

.secondary-dark {
  color: #6A162B !important;
}

.tertiary {
  color: #7BA7C5 !important;
}

.tertiary-light {
  color: #8db3cd !important;
}

.tertiary-dark {
  color: #5D8AA8 !important;
}

.gray {
  color: #F5F9FD !important;
}

.gray-light {
  color: white !important;
}

.gray-dark {
  color: #ECF0F3 !important;
}

.gray-darker {
  color: #B5B5B5 !important;
}

b,
strong,
.bold {
  font-weight: 700;
}

.flex {
  display: flex;
}

.flex--center {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex--center {
    align-items: stretch;
  }
}

.flex--center-left {
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex--center-left {
    align-items: stretch;
  }
}

.flex--center-right {
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}

.flex--top {
  justify-content: flex-start !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex-ie {
    justify-content: flex-start !important;
  }
}

.img-ratio16-9 {
  padding-top: 56.25% !important;
}

.img-ratio4-3 {
  padding-top: 75% !important;
}

.img-ratio3-2 {
  padding-top: 66.66% !important;
}

.img-ratio8-5 {
  padding-top: 62.5% !important;
}

.img-tall {
  padding-top: 110% !important;
}

.img-square {
  padding-top: 100% !important;
}

.img-circle {
  border-radius: 50%;
  padding-top: 100% !important;
}

.img-round {
  border-radius: 50%;
}

.img-rounded {
  border-radius: 8px;
}

.img-br {
  position: absolute;
  bottom: -30%;
  right: -30px;
}

@media only screen and (max-width: 991px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .mobile-only {
    display: none !important;
  }
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#showreel button {
  position: absolute;
  bottom: -100px;
  left: calc(50% - 60px);
}

#twitter-widget-0 {
  border: 3px solid #F5F9FD !important;
}

ul {
  list-style-image: url("./images/icons/arrow-bullet.svg");
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 3px solid transparent;
  border-radius: 0;
  padding: 0 3rem;
  height: 6rem;
  cursor: pointer;
  text-decoration: none;
  color: #FFF;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  min-width: 12rem;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media only screen and (max-width: 991px) {
  .button {
    height: 5.5rem;
    min-width: 10rem;
  }
}

.button:hover,
.button:focus,
.button:active {
  text-decoration: none;
  color: #FFF;
}

.button-small {
  height: 5.5rem;
}

.button.tiny-block {
  width: 3rem;
  height: 3rem;
  padding: 0;
  min-width: 0;
  z-index: 5;
}

.button.thin {
  padding: 0 1rem;
}

.button.wide {
  min-width: 21rem;
}

.button--primary {
  background: #3A3C48;
  border-color: #3A3C48;
}

.button--primary:hover,
.button--primary:focus,
.button--primary:active {
  background: #141622;
  border-color: #141622;
}

.button--primary-outline {
  background: transparent;
  border-color: #3A3C48;
  color: inherit;
}

.button--primary-outline:hover,
.button--primary-outline:focus,
.button--primary-outline:active {
  background: #3A3C48;
}

.button--primary-outline.black:hover {
  color: white !important;
}

.button--primary-light {
  background: #454856;
  border-color: #454856;
}

.button--primary-light:hover,
.button--primary-light:focus,
.button--primary-light:active {
  background: #3A3C48;
  border-color: #3A3C48;
}

.button--primary-light-outline {
  background: transparent;
  border-color: #454856;
  color: inherit;
}

.button--primary-light-outline:hover,
.button--primary-light-outline:focus,
.button--primary-light-outline:active {
  background: #454856;
}

.button--primary-light-outline.black:hover {
  color: white !important;
}

.button--primary-dark {
  background: #141622;
  border-color: #141622;
}

.button--primary-dark:hover,
.button--primary-dark:focus,
.button--primary-dark:active {
  background: #3A3C48;
  border-color: #3A3C48;
}

.button--primary-dark-outline {
  background: transparent;
  border-color: #141622;
  color: inherit;
}

.button--primary-dark-outline:hover,
.button--primary-dark-outline:focus,
.button--primary-dark-outline:active {
  background: #141622;
}

.button--primary-dark-outline.black:hover,
.button--primary-dark-outline.black:focus,
.button--primary-dark-outline.black:active {
  color: white !important;
}

.button--secondary {
  background: #992945;
  border-color: #992945;
}

.button--secondary:hover,
.button--secondary:focus,
.button--secondary:active {
  background: #6A162B;
  border-color: #6A162B;
}

.button--secondary-outline {
  background: transparent;
  border-color: #992945;
  color: inherit;
}

.button--secondary-outline:hover,
.button--secondary-outline:focus,
.button--secondary-outline:active {
  background: #992945;
}

.button--secondary-outline.black:hover,
.button--secondary-outline.black:focus,
.button--secondary-outline.black:active {
  color: white !important;
}

.button--secondary-light {
  background: #ad2e4e;
  border-color: #ad2e4e;
}

.button--secondary-light:hover,
.button--secondary-light:focus,
.button--secondary-light:active {
  background: #992945;
  border-color: #992945;
}

.button--secondary-light-outline {
  background: transparent;
  border-color: #ad2e4e;
  color: inherit;
}

.button--secondary-light-outline:hover,
.button--secondary-light-outline:focus,
.button--secondary-light-outline:active {
  background: #ad2e4e;
}

.button--secondary-light-outline.black:hover,
.button--secondary-light-outline.black:focus,
.button--secondary-light-outline.black:active {
  color: white !important;
}

.button--secondary-dark {
  background: #6A162B;
  border-color: #6A162B;
}

.button--secondary-dark:hover,
.button--secondary-dark:focus,
.button--secondary-dark:active {
  background: #992945;
  border-color: #992945;
}

.button--secondary-dark-outline {
  background: transparent;
  border-color: #6A162B;
  color: inherit;
}

.button--secondary-dark-outline:hover,
.button--secondary-dark-outline:focus,
.button--secondary-dark-outline:active {
  background: #6A162B;
}

.button--secondary-dark-outline.black:hover,
.button--secondary-dark-outline.black:focus,
.button--secondary-dark-outline.black:active {
  color: white !important;
}

.button--tertiary {
  background: #7BA7C5;
  border-color: #7BA7C5;
}

.button--tertiary:hover,
.button--tertiary:focus,
.button--tertiary:active {
  background: #5D8AA8;
  border-color: #5D8AA8;
}

.button--tertiary-outline {
  background: transparent;
  border-color: #7BA7C5;
  color: inherit;
}

.button--tertiary-outline:hover,
.button--tertiary-outline:focus,
.button--tertiary-outline:active {
  background: #8db3cd;
}

.button--tertiary-outline.black:hover,
.button--tertiary-outline.black:focus,
.button--tertiary-outline.black:active {
  color: white !important;
}

.button--tertiary-light {
  background: #8db3cd;
  border-color: #8db3cd;
}

.button--tertiary-light:hover,
.button--tertiary-light:focus,
.button--tertiary-light:active {
  background: #7BA7C5;
  border-color: #7BA7C5;
}

.button--tertiary-light-outline {
  background: transparent;
  border-color: #8db3cd;
  color: inherit;
}

.button--tertiary-light-outline:hover,
.button--tertiary-light-outline:focus,
.button--tertiary-light-outline:active {
  background: #8db3cd;
}

.button--tertiary-light-outline.black:hover,
.button--tertiary-light-outline.black:focus,
.button--tertiary-light-outline.black:active {
  color: white !important;
}

.button--tertiary-dark {
  background: #5D8AA8;
  border-color: #5D8AA8;
}

.button--tertiary-dark:hover,
.button--tertiary-dark:focus,
.button--tertiary-dark:active {
  background: #7BA7C5;
  border-color: #7BA7C5;
}

.button--tertiary-dark-outline {
  background: transparent;
  border-color: #5D8AA8;
  color: inherit;
}

.button--tertiary-dark-outline:hover,
.button--tertiary-dark-outline:focus,
.button--tertiary-dark-outline:active {
  background: #7BA7C5;
}

.button--tertiary-dark-outline.black:hover,
.button--tertiary-dark-outline.black:focus,
.button--tertiary-dark-outline.black:active {
  color: white !important;
}

.button--gray {
  background: #F5F9FD;
  border-color: #F5F9FD;
}

.button--gray:hover,
.button--gray:focus,
.button--gray:active {
  background: #ECF0F3;
  border-color: #ECF0F3;
}

.button--gray-outline {
  background: transparent;
  border-color: #F5F9FD;
  color: inherit;
}

.button--gray-outline:hover,
.button--gray-outline:focus,
.button--gray-outline:active {
  background: #ECF0F3;
}

.button--gray-outline.black:hover,
.button--gray-outline.black:focus,
.button--gray-outline.black:active {
  color: white !important;
}

.button--gray-light {
  background: white;
  border-color: white;
}

.button--gray-light:hover,
.button--gray-light:focus,
.button--gray-light:active {
  background: #F5F9FD;
  border-color: #F5F9FD;
}

.button--gray-light-outline {
  background: transparent;
  border-color: white;
  color: inherit;
}

.button--gray-light-outline:hover,
.button--gray-light-outline:focus,
.button--gray-light-outline:active {
  background: white;
}

.button--gray-light-outline.black:hover,
.button--gray-light-outline.black:focus,
.button--gray-light-outline.black:active {
  color: white !important;
}

.button--gray-dark {
  background: #ECF0F3;
  border-color: #ECF0F3;
}

.button--gray-dark:hover,
.button--gray-dark:focus,
.button--gray-dark:active {
  background: #F5F9FD;
  border-color: #F5F9FD;
}

.button--gray-dark-outline {
  background: transparent;
  border-color: #ECF0F3;
  color: inherit;
}

.button--gray-dark-outline:hover,
.button--gray-dark-outline:focus,
.button--gray-dark-outline:active {
  background: #F5F9FD;
}

.button--gray-dark-outline.black:hover,
.button--gray-dark-outline.black:focus,
.button--gray-dark-outline.black:active {
  color: white !important;
}

.button--white {
  background: white;
  border-color: white;
  color: black;
}

.button--white:hover,
.button--white:focus,
.button--white:active {
  background: #F5F9FD;
  border-color: #F5F9FD;
  color: black;
}

.button--white-outline {
  background: transparent;
  border-color: white;
}

.button--white-outline:hover,
.button--white-outline:focus,
.button--white-outline:active {
  border-color: #F5F9FD;
  background: #F5F9FD;
  color: black;
}

.button--black {
  background: black;
  border-color: black;
  color: white;
}

.button--black:hover,
.button--black:focus,
.button--black:active {
  background: #141622;
  border-color: #141622;
  color: white;
}

.button--black-outline {
  background: transparent;
  border-color: black;
}

.button--black-outline:hover,
.button--black-outline:focus,
.button--black-outline:active {
  border-color: #141622;
  background: #141622;
  color: white;
}

.button--transparent {
  background: transparent;
  border-color: transparent;
  color: black;
}

.button--transparent:hover,
.button--transparent:focus,
.button--transparent:active {
  background: white;
  border-color: white;
  color: black;
}

.button--transparent.white:hover,
.button--transparent.white:focus,
.button--transparent.white:active {
  color: black !important;
}

.button--transparent-dark:hover,
.button--transparent-dark:focus,
.button--transparent-dark:active {
  border-color: #141622;
  background: #141622;
  color: white;
}

.fa.via-bars:before {
  mask: url("./images/icons/bars.svg") no-repeat 50% 50%;
}

.fa.via-bullet:before {
  mask: url("./images/icons/bullet.svg") no-repeat 50% 50%;
}

.fa.via-cart:before {
  mask: url("./images/icons/cart.svg") no-repeat 50% 50%;
}

.fa.via-chevron-up:before {
  mask: url("./images/icons/chevron-up.svg") no-repeat 50% 50%;
}

.fa.via-chevron-down:before {
  mask: url("./images/icons/chevron-down.svg") no-repeat 50% 50%;
}

.fa.via-chevron-left:before {
  mask: url("./images/icons/chevron-left.svg") no-repeat 50% 50%;
}

.fa.via-chevron-right:before {
  mask: url("./images/icons/chevron-right.svg") no-repeat 50% 50%;
}

.fa.via-cross:before {
  mask: url("./images/icons/cross.svg") no-repeat 50% 50%;
}

.fa.via-email:before {
  mask: url("./images/icons/email.svg") no-repeat 50% 50%;
}

.fa.via-quote:before {
  mask: url("./images/icons/quote.svg") no-repeat 50% 50%;
}

.fa.via-map:before {
  mask: url("./images/icons/map.svg") no-repeat 50% 50%;
}

.fa.via-map-outline:before {
  mask: url("./images/icons/map-outline.svg") no-repeat 50% 50%;
}

.fa.via-plus:before {
  mask: url("./images/icons/plus.svg") no-repeat 50% 50%;
}

.fa.via-minus:before {
  mask: url("./images/icons/minus.svg") no-repeat 50% 50%;
}

.fa.via-phone:before {
  mask: url("./images/icons/phone.svg") no-repeat 50% 50%;
}

.fa.via-reset:before {
  mask: url("./images/icons/reset.svg") no-repeat 50% 50%;
}

.fa.via-search:before {
  mask: url("./images/icons/search.svg") no-repeat 50% 50%;
}

.fa.via-tick:before {
  mask: url("./images/icons/tick.svg") no-repeat 50% 50%;
}

.fa[class*=via-] {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  flex-shrink: 0;
}

.fa[class*=via-]:before {
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  content: "";
  mask-size: contain;
  position: absolute;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

.fa[class*=via-].primary:before {
  background-color: #3A3C48;
}

.fa[class*=via-].primary-light:before {
  background-color: #454856;
}

.fa[class*=via-].primary-dark:before {
  background-color: #141622;
}

.fa[class*=via-].secondary:before {
  background-color: #992945;
}

.fa[class*=via-].secondary-light:before {
  background-color: #ad2e4e;
}

.fa[class*=via-].secondary-dark:before {
  background-color: #6A162B;
}

.fa[class*=via-].tertiary:before {
  background-color: #7BA7C5;
}

.fa[class*=via-].tertiary-light:before {
  background-color: #8db3cd;
}

.fa[class*=via-].tertiary-dark:before {
  background-color: #5D8AA8;
}

.fa[class*=via-].gray:before {
  background-color: #F5F9FD;
}

.fa[class*=via-].gray-light:before {
  background-color: white;
}

.fa[class*=via-].gray-dark:before {
  background-color: #ECF0F3;
}

.fa[class*=via-].gray-darker:before {
  background-color: #B5B5B5;
}

.fa[class*=via-].black:before {
  background-color: black;
}

.fa[class*=via-].white:before {
  background-color: white;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  height: 22rem;
  width: 100vw;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media only screen and (max-width: 991px) {
  nav {
    height: 11rem;
  }

  nav .slant {
    height: 11rem;
  }

  nav .slant-top-left:before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  nav .slant-top-right:after {
    right: 0;
    width: 66%;
    background-image: none !important;
  }
}

@media only screen and (min-width: 992px) {
  nav.scroll {
    height: 11rem;
  }

  nav.scroll .slant {
    height: 11rem;
  }

  nav.scroll .slant-top-left:before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    height: 11rem;
  }

  nav.scroll .slant-top-right:after {
    height: 0;
  }

  nav.scroll .logo p.small {
    opacity: 0;
    position: absolute;
  }
}

nav .slant-top-right:after {
  background-image: url("./images/globe.png");
  background-size: 300px;
  background-repeat: no-repeat;
  background-position-x: 75%;
  background-position-y: center;
}

nav .logo {
  width: 29.5rem;
  padding: 0 3rem;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  nav .logo {
    width: 20rem;
    padding-left: 2rem;
  }
}

nav .logo img {
  max-width: 100%;
}

nav .logo p.small {
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin: 1rem 0 0;
  font-family: "Poppins", sans-serif;
  opacity: 1;
  position: relative;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

nav .buttons {
  padding: 0 3rem;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  nav .buttons {
    padding-right: 2rem;
    -webkit-transition: 0.5s ease all;
    -moz-transition: 0.5s ease all;
    -ms-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
    transition: 0.5s ease all;
  }

  body.overflow nav .buttons {
    padding-right: 1rem;
  }
}

@media only screen and (max-width: 991px) {
  nav .buttons .open-menu {
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    padding: 0;
  }
}

nav .buttons .open-menu > i {
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

nav .buttons .via-bars,
nav .buttons .via-cross {
  height: 1.2em;
  overflow: visible;
}

@media only screen and (max-width: 991px) {
  nav .buttons .via-bars:before,
  nav .buttons .via-cross:before {
    background-color: white !important;
  }
}

ul.big-menu {
  list-style: none;
  padding: 0;
  font-size: 1.9rem;
  line-height: 2.4rem;
}

#mobile-menu ul.big-menu.open {
  left: -90vw;
}

#mobile-menu ul.big-menu:after {
  height: 100vh;
  width: 85vw;
  display: block;
  content: "";
  position: absolute;
  z-index: 0;
  left: 100%;
  top: 0;
  background: #3A3C48;
}

#menu ul.big-menu li {
  margin-bottom: 1rem;
}

#menu ul.big-menu li:last-child {
  margin-bottom: 0;
}

#mobile-menu ul.big-menu li {
  margin-bottom: 2rem;
}

#mobile-menu ul.big-menu li:last-child {
  margin-bottom: 0;
}

ul.big-menu li a,
ul.big-menu li span {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #7BA7C5;
}

ul.big-menu li a:hover,
ul.big-menu li a:focus,
ul.big-menu li span:hover,
ul.big-menu li span:focus {
  color: #5D8AA8;
}

#mobile-menu ul.big-menu li a:not(.button),
#mobile-menu ul.big-menu li span:not(.button) {
  color: white;
  display: block;
}

#mobile-menu ul.big-menu li a.button,
#mobile-menu ul.big-menu li span.button {
  margin-top: 2rem;
}

#mobile-menu ul.big-menu li a.sub-menu,
#mobile-menu ul.big-menu li span.sub-menu {
  display: flex;
  align-items: center;
}

#mobile-menu ul.big-menu li a.sub-menu.open:after,
#mobile-menu ul.big-menu li span.sub-menu.open:after {
  margin-right: -2rem;
}

#mobile-menu ul.big-menu li a.sub-menu:after,
#mobile-menu ul.big-menu li span.sub-menu:after {
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  content: "";
  margin-left: auto;
  margin-right: 0;
  mask-size: contain;
  mask: url("./images/icons/chevron-right.svg") no-repeat 50% 50%;
  background: #992945;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

ul.small-menu {
  list-style: none;
  padding: 0;
  font-size: 1.4rem;
}

#mobile-menu ul.small-menu {
  position: absolute;
  left: 100%;
  top: 0;
  padding: 3rem 2rem;
  background: #3A3C48;
  height: 100vh;
  width: 85vw;
  display: none;
  overflow-y: scroll;
  z-index: 1;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

#mobile-menu ul.small-menu.show {
  display: block;
}

#menu ul.small-menu li,
#mobile-menu ul.small-menu li {
  margin-bottom: 1rem;
}

#menu ul.small-menu li:last-child,
#mobile-menu ul.small-menu li:last-child {
  margin-bottom: 0;
}

ul.small-menu li a:not(.button) {
  display: inline-block;
  color: black;
  font-weight: 500;
}

#menu ul.small-menu li a:not(.button),
#mobile-menu ul.small-menu li a:not(.button) {
  color: white;
}

#menu ul.small-menu li a:not(.button):hover,
#menu ul.small-menu li a:not(.button):focus,
#mobile-menu ul.small-menu li a:not(.button):hover,
#mobile-menu ul.small-menu li a:not(.button):focus {
  color: #B5B5B5;
}

#menu {
  position: fixed;
  z-index: 555;
  height: 100vh;
  width: 100vw;
  top: -100%;
  left: 0;
  padding: 6rem;
  background-color: #141622;
  background-image: url("./images/globe.png");
  background-size: 35%;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 180%;
  opacity: 0;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media only screen and (max-width: 991px) {
  #menu {
    display: none;
  }
}

#menu.show {
  top: 0;
  opacity: 1;
}

#menu hr {
  border-color: #3A3C48;
}

#menu .close-menu {
  position: absolute;
  bottom: 0;
  width: 120px;
  left: calc(50% - 60px);
}

#mobile-menu {
  position: fixed;
  z-index: 555;
  height: 100vh;
  width: 85vw;
  top: 0;
  left: 100%;
  padding: 3rem 2rem;
  background-color: #141622;
  border-left: 1px solid rgba(123, 167, 197, 0.2);
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media only screen and (min-width: 992px) {
  #mobile-menu {
    display: none;
  }
}

#mobile-menu.show {
  left: 15vw;
}

#mobile-menu.show.show-sub-menu {
  left: -70vw;
}

#mobile-menu hr {
  border-color: #3A3C48;
}

#mobile-menu .close-menu {
  position: absolute;
  bottom: 0;
  width: 120px;
  left: calc(50% - 60px);
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 4rem 0;
  position: relative;
  min-height: 100vh;
}

@media only screen and (max-width: 991px) {
  header {
    padding-top: 11rem;
    padding-bottom: 10rem;
  }
}

header.home:after {
  display: none;
}

header:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url("./images/header-foot.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

header:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
  background: black;
  opacity: 0.25;
}

header.space:before {
  background-image: url("./images/Cosmo.jpg");
}

header.space:after {
  display: none;
}

@media only screen and (min-width: 992px) {
  header {
    padding: 24rem 0;
  }
}

header > div {
  z-index: 2;
}

header h1,
header h2.large,
header .cart-item .course-price .large.price,
.cart-item .course-price header .large.price,
header .purchase-card .large.price,
.purchase-card header .large.price,
header .contact-list-boxed li.large,
.contact-list-boxed header li.large,
header .share p.large,
.share header p.large,
header .details .info p.large {
  color: white;
}

header.spacer {
  padding: 12rem 0;
  min-height: auto;
  background-color: transparent;
}

header.spacer:before,
header.spacer:after {
  display: none;
}

header.spacer.primary {
  background-color: #3A3C48 !important;
}

header.spacer.primary-light {
  background-color: #454856 !important;
}

header.spacer.primary-dark {
  background-color: #141622 !important;
}

header.spacer.secondary {
  background-color: #992945 !important;
}

header.spacer.secondary-light {
  background-color: #ad2e4e !important;
}

header.spacer.secondary-dark {
  background-color: #6A162B !important;
}

header.spacer.tertiary {
  background-color: #7BA7C5 !important;
}

header.spacer.tertiary-light {
  background-color: #8db3cd !important;
}

header.spacer.tertiary-dark {
  background-color: #5D8AA8 !important;
}

header.spacer.gray {
  background-color: #F5F9FD !important;
}

header.spacer.gray-light {
  background-color: white !important;
}

header.spacer.gray-dark {
  background-color: #ECF0F3 !important;
}

header.spacer.gray-darker {
  background-color: #B5B5B5 !important;
}

header.spacer.white {
  background-color: white !important;
}

header.spacer.black {
  background-color: black !important;
}

@media only screen and (max-width: 991px) {
  header.spacer {
    padding-top: 0rem;
  }
}

header .details {
  padding: 3rem;
  background: rgba(255, 255, 255, 0.1);
}

header .details .info h5 {
  color: white;
}

header .details .info p {
  color: #7BA7C5;
}

header .details .info + .info {
  margin-top: 3rem;
}

.scroll-icon {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  border-radius: 50%;
  border: 3px solid #ddd;
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  color: #ddd;
  z-index: 555;
  opacity: 1;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.scroll-icon.scroll {
  opacity: 0;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  border-bottom: 9px solid #7BA7C5;
}

footer .sponsers img {
  max-height: 80px;
  max-width: 130px;
  margin-right: 1.5rem;
}

@media only screen and (max-width: 991px) {
  footer .sponsers img {
    width: 50%;
    object-fit: contain;
    margin-bottom: 3rem;
    margin-right: 0;
  }
}

footer .sponsers img:last-child {
  margin-right: 0;
}

footer h4 {
  color: #7BA7C5;
}

footer p {
  margin: 0;
}

.sponsers {
  text-align: center;
}

.sponsers img {
  max-height: 110px;
  max-width: 180px;
  margin-right: 3rem;
}

.sponsers img:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 991px) {
  .sponsers img {
    max-width: 80%;
    object-fit: contain;
    margin-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .sponsers > .col-6 {
    margin-bottom: 3rem;
  }

  .sponsers > .col-6:last-child,
  .sponsers > .col-6:nth-last-child(2) {
    margin-bottom: 0;
  }
}

section {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.bkgd-primary {
  background-color: #3A3C48;
  color: white;
}

.bkgd-primary-light {
  background-color: #454856;
  color: white;
}

.bkgd-primary-dark {
  background-color: #141622;
  color: white;
}

.bkgd-secondary {
  background-color: #992945;
  color: white;
}

.bkgd-secondary-light {
  background-color: #ad2e4e;
  color: white;
}

.bkgd-secondary-dark {
  background-color: #6A162B;
  color: white;
}

.bkgd-tertiary {
  background-color: #7BA7C5;
  color: white;
}

.bkgd-tertiary-light {
  background-color: #8db3cd;
  color: white;
}

.bkgd-tertiary-dark {
  background-color: #5D8AA8;
  color: white;
}

.bkgd-gray {
  background-color: #F5F9FD;
}

.bkgd-gray-light {
  background-color: white;
}

.bkgd-gray-dark {
  background-color: #ECF0F3;
  color: white;
}

.bkgd-gray-darker {
  background-color: #B5B5B5;
  color: white;
}

.bkgd-white {
  background-color: white;
}

.bkgd-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bkgd-image-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bkgd-image-wide {
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}

.bkgd-image-left:after {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  display: block;
  content: "";
  height: 100%;
  width: 52%;
  position: absolute;
  z-index: 0;
  left: 0;
}

.bkgd-image-right:after {
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  display: block;
  content: "";
  height: 100%;
  width: 52%;
  position: absolute;
  z-index: 0;
  right: 0;
}

.button-box {
  padding: 3rem 3rem 0;
  color: white;
  min-height: 27rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.button-box:after {
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #7BA7C5;
  opacity: 0.8;
}

.button-box h2,
.button-box .cart-item .course-price .price,
.cart-item .course-price .button-box .price,
.button-box .purchase-card .price,
.purchase-card .button-box .price,
.button-box .contact-list-boxed li,
.contact-list-boxed .button-box li,
.button-box header .details .info p,
header .details .info .button-box p,
.button-box a,
.button-box p {
  z-index: 5;
}

.button-box h2,
.button-box .cart-item .course-price .price,
.cart-item .course-price .button-box .price,
.button-box .purchase-card .price,
.purchase-card .button-box .price,
.button-box .contact-list-boxed li,
.contact-list-boxed .button-box li,
.button-box .share p,
.share .button-box p,
.button-box header .details .info p,
header .details .info .button-box p {
  color: white;
  margin-bottom: 2rem;
}

.button-box .button {
  margin-top: auto;
}

.bkgd-image-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bkgd-image-block:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #141622;
  opacity: 0.5;
}

.no-overlay:before,
.no-overlay:after {
  display: none;
}

.raise {
  position: relative;
  z-index: 5;
}

.book a:hover img:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.5;
}

.book img {
  max-height: 250px;
  margin: 0 auto;
}

.quote .author {
  display: flex;
  margin-top: 3rem;
}

.quote .author .img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin-right: 3rem;
}

.share {
  padding: 5rem 0;
}

.share p {
  display: inline-block;
  color: #7BA7C5;
  margin-right: 5rem;
}

.share .social-icons {
  display: inline-flex;
}

.course-list {
  position: relative;
  margin-top: -350px;
  z-index: 5;
}

.course-list:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #F5F9FD;
  -webkit-clip-path: polygon(0 351px, 100% 351px, 100% 100%, 0% 100%);
  clip-path: polygon(0 351px, 100% 351px, 100% 100%, 0% 100%);
}

.exam-info {
  background: #F5F9FD;
  padding: 3rem;
  margin-top: 3rem;
}

.exam-info .info .label {
  display: inline-block;
  width: 25rem;
}

.exam-info .info .value {
  display: inline-block;
  color: #7BA7C5;
}

@media only screen and (max-width: 991px) {
  .address .content {
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .address .map {
    margin-top: 5rem;
    margin-bottom: -5rem;
    padding: 0;
    height: 30rem;
  }
}

.social-icons {
  list-style: none;
  padding: 0;
  display: flex;
}

@media only screen and (max-width: 991px) {
  .social-icons {
    justify-content: center;
  }
}

.social-icons li a {
  display: flex;
  font-size: 2rem;
  width: 5rem;
  height: 5rem;
  align-items: center;
  justify-content: center;
  border: 3px solid #992945;
  margin-right: 3rem;
  color: black;
  transition: 0.5s ease;
}

@media only screen and (max-width: 991px) {
  footer .social-icons li a {
    color: #992945;
    background: #F5F9FD;
    border-color: #F5F9FD;
  }
}

.social-icons li a:hover,
.social-icons li a:focus,
.social-icons li a:active {
  text-decoration: none;
  background: #992945;
  color: white;
}

.social-icons li a:hover .fa[class*=via-]:before,
.social-icons li a:focus .fa[class*=via-]:before,
.social-icons li a:active .fa[class*=via-]:before {
  background-color: white;
}

form .form-control,
.cart-item .quantity form label input {
  border-radius: 0;
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  min-height: 60px;
  border: 3px solid #ECF0F3;
  color: black;
}

@media only screen and (max-width: 991px) {
  form .form-control,
  .cart-item .quantity form label input {
    min-height: 60px;
  }
}

form .form-control::placeholder,
.cart-item .quantity form label input::placeholder {
  color: black;
}

form .form-control:focus,
.cart-item .quantity form label input:focus {
  outline: none;
  box-shadow: none;
  border: 3px solid #992945;
}

form select.form-control {
  text-align: left;
  text-align-last: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='18' viewBox='0 0 24 24'><path fill='grey' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") #fff;
  background-position: 98% 50%;
  background-repeat: no-repeat;
}

form label {
  width: 100%;
}

form label .form-control,
.cart-item .quantity form label input {
  display: inline-block;
  width: auto;
  margin-right: 1rem;
}

.dark form .form-control,
.dark .cart-item .quantity form label input,
.cart-item .quantity .dark form label input,
form.dark .form-control,
.cart-item .quantity form.dark label input {
  background-color: #F5F9FD;
}

.dark form .checkbox,
form.dark .checkbox {
  color: black;
}

@media only screen and (max-width: 767px) {
  form input[type=submit] {
    width: 90%;
    margin: 0 auto;
    max-width: 230px;
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 4rem;
  margin-bottom: 1rem;
  cursor: pointer;
  user-select: none;
  color: black;
}

.checkbox input {
  position: absolute;
  cursor: pointer;
  visibility: hidden;
}

.checkbox input:before {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  content: "";
  position: absolute;
  top: 5px;
  left: -4rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: transparent;
  border: 2px solid #7BA7C5;
  border-radius: 50%;
  visibility: visible;
}

.checkbox input:focus:before,
.checkbox input:hover:before,
.checkbox input:checked:before {
  background-color: #7BA7C5;
  content: url("./images/icons/tick.svg");
}

.checkbox input:focus:before,
.checkbox input:hover:before {
  border-color: #5D8AA8;
}

.checkbox label {
  cursor: pointer;
}

.dark .checkbox input:before {
  border: 2px solid #7BA7C5;
}

.dark .checkbox input:hover:before,
.dark .checkbox input:checked:before {
  background-color: #7BA7C5;
}

@media only screen and (max-width: 991px) {
  .login .button {
    width: 75%;
    max-width: 100%;
  }

  .login .button:last-child {
    margin-top: 1.5rem;
  }
}

.contact-list {
  list-style: none;
  padding: 0;
}

.contact-list li {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 991px) {
  .contact-list li {
    background: white;
    margin-bottom: 0;
    padding: 1.5rem;
    display: flex;
    align-items: center;
  }

  .contact-list li:first-child {
    background: #F5F9FD;
  }
}

.contact-list li i {
  text-align: center;
  margin-right: 1rem;
}

.contact-list li i:before {
  background-color: #7BA7C5;
}

@media only screen and (max-width: 991px) {
  .contact-list li i:before {
    background-color: #992945;
  }
}

.contact-list li a {
  color: white;
}

@media only screen and (max-width: 991px) {
  .contact-list li a {
    color: black;
    white-space: nowrap;
    overflow: hidden;
  }
}

.contact-list li a:hover,
.contact-list li a:focus,
.contact-list li a:active {
  color: #B5B5B5;
  text-decoration: none;
}

.contact-list-boxed {
  list-style: none;
  padding: 0;
}

.contact-list-boxed li {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

@media only screen and (max-width: 991px) {
  .contact-list-boxed li {
    font-size: 1.9rem;
    line-height: 2.7rem;
  }
}

.contact-list-boxed li i {
  width: 5rem !important;
  height: 5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  background: #992945;
  flex-shrink: 0;
}

.contact-list-boxed li i:before {
  width: 1em !important;
  height: 1em !important;
  background-color: white;
  position: relative !important;
}

.contact-list-boxed li a {
  color: #3A3C48;
}

.contact-list-boxed li a:hover,
.contact-list-boxed li a:focus,
.contact-list-boxed li a:active {
  text-decoration: none;
  color: #141622;
}

.course-chapters {
  list-style: none;
  padding: 0;
}

.course-chapters li {
  margin-bottom: 2px;
  position: relative;
}

.course-chapters li a {
  display: block;
  background: #F5F9FD;
  padding: 1.25rem;
  color: #3A3C48;
}

.course-chapters li a:hover,
.course-chapters li a:focus {
  background: #ECF0F3;
  outline: none;
}

.course-chapters li:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
  top: 1.4rem;
  right: 1.25rem;
  width: 2.2rem;
  height: 2.2rem;
  color: white;
  background: white;
  border-radius: 50%;
  border: 2px solid #ECF0F3;
}

.course-chapters li.complete a {
  color: #B5B5B5;
}

.course-chapters li.complete:after {
  background: #992945;
  border-color: #992945;
  content: url("./images/icons/tick.svg");
}

.course-chapters li.complete + li:not(.complete):after {
  border-color: #992945;
}

.menu-list {
  list-style: none;
  padding: 0;
}

.menu-list li {
  margin-bottom: 2rem;
  position: relative;
}

.menu-list li a {
  display: block;
  background: #F5F9FD;
  padding: 1.25rem;
  padding-right: 4.25rem;
  color: #3A3C48;
}

.menu-list li a:hover,
.menu-list li a:focus {
  background: #ECF0F3;
  outline: none;
}

.menu-list li:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "";
  mask: url("./images/icons/chevron-right.svg") no-repeat 50% 50%;
  top: calc(50% - 1.1rem);
  right: 1.25rem;
  width: 2.2rem;
  height: 2.2rem;
  color: #992945;
  background: #992945;
}

.slant {
  height: 22rem;
  width: 100%;
  position: absolute;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media only screen and (max-width: 991px) {
  .slant {
    height: 11rem;
  }
}

.slant:before,
.slant:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
}

nav .slant:before,
nav .slant:after {
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.slant-short {
  height: 11rem;
}

.slant-top-left {
  top: 0;
}

.slant-top-left:before {
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

.slant-top-left-primary:before {
  background: #3A3C48;
}

.slant-top-left-primary-light:before {
  background: #454856;
}

.slant-top-left-primary-dark:before {
  background: #141622;
}

.slant-top-left-secondary:before {
  background: #992945;
}

.slant-top-left-secondary-light:before {
  background: #ad2e4e;
}

.slant-top-left-secondary-dark:before {
  background: #6A162B;
}

.slant-top-left-tertiary:before {
  background: #7BA7C5;
}

.slant-top-left-tertiary-light:before {
  background: #8db3cd;
}

.slant-top-left-tertiary-dark:before {
  background: #5D8AA8;
}

.slant-top-left-gray:before {
  background: #F5F9FD;
}

.slant-top-left-gray-light:before {
  background: white;
}

.slant-top-left-gray-dark:before {
  background: #ECF0F3;
}

.slant-top-left-gray-darker:before {
  background: #B5B5B5;
}

.slant-top-left-white:before {
  background: white;
}

.slant-top-left-black:before {
  background: black;
}

.slant-top-right {
  top: 0;
}

.slant-top-right:after {
  z-index: 2;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
}

.slant-top-right-primary:after {
  background: #3A3C48;
}

.slant-top-right-primary-light:after {
  background: #454856;
}

.slant-top-right-primary-dark:after {
  background: #141622;
}

.slant-top-right-secondary:after {
  background: #992945;
}

.slant-top-right-secondary-light:after {
  background: #ad2e4e;
}

.slant-top-right-secondary-dark:after {
  background: #6A162B;
}

.slant-top-right-tertiary:after {
  background: #7BA7C5;
}

.slant-top-right-tertiary-light:after {
  background: #8db3cd;
}

.slant-top-right-tertiary-dark:after {
  background: #5D8AA8;
}

.slant-top-right-gray:after {
  background: #F5F9FD;
}

.slant-top-right-gray-light:after {
  background: white;
}

.slant-top-right-gray-dark:after {
  background: #ECF0F3;
}

.slant-top-right-gray-darker:after {
  background: #B5B5B5;
}

.slant-top-right-white:after {
  background: white;
}

.slant-top-right-black:after {
  background: black;
}

.slant-bottom-left {
  bottom: -1px;
}

.slant-bottom-left:before {
  z-index: 2;
  -webkit-clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
}

.slant-bottom-left-primary:before {
  background: #3A3C48;
}

.slant-bottom-left-primary-light:before {
  background: #454856;
}

.slant-bottom-left-primary-dark:before {
  background: #141622;
}

.slant-bottom-left-secondary:before {
  background: #992945;
}

.slant-bottom-left-secondary-light:before {
  background: #ad2e4e;
}

.slant-bottom-left-secondary-dark:before {
  background: #6A162B;
}

.slant-bottom-left-tertiary:before {
  background: #7BA7C5;
}

.slant-bottom-left-tertiary-light:before {
  background: #8db3cd;
}

.slant-bottom-left-tertiary-dark:before {
  background: #5D8AA8;
}

.slant-bottom-left-gray:before {
  background: #F5F9FD;
}

.slant-bottom-left-gray-light:before {
  background: white;
}

.slant-bottom-left-gray-dark:before {
  background: #ECF0F3;
}

.slant-bottom-left-gray-darker:before {
  background: #B5B5B5;
}

.slant-bottom-left-white:before {
  background: white;
}

.slant-bottom-left-black:before {
  background: black;
}

.slant-bottom-right {
  bottom: -1px;
}

.slant-bottom-right:after {
  z-index: 1;
  -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
}

.slant-bottom-right-primary:after {
  background: #3A3C48;
}

.slant-bottom-right-primary-light:after {
  background: #454856;
}

.slant-bottom-right-primary-dark:after {
  background: #141622;
}

.slant-bottom-right-secondary:after {
  background: #992945;
}

.slant-bottom-right-secondary-light:after {
  background: #ad2e4e;
}

.slant-bottom-right-secondary-dark:after {
  background: #6A162B;
}

.slant-bottom-right-tertiary:after {
  background: #7BA7C5;
}

.slant-bottom-right-tertiary-light:after {
  background: #8db3cd;
}

.slant-bottom-right-tertiary-dark:after {
  background: #5D8AA8;
}

.slant-bottom-right-gray:after {
  background: #F5F9FD;
}

.slant-bottom-right-gray-light:after {
  background: white;
}

.slant-bottom-right-gray-dark:after {
  background: #ECF0F3;
}

.slant-bottom-right-gray-darker:after {
  background: #B5B5B5;
}

.slant-bottom-right-white:after {
  background: white;
}

.slant-bottom-right-black:after {
  background: black;
}

.box-slant {
  padding: 6rem 0;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .box-slant {
    padding: 3rem 0;
  }
}

.box-slant:before,
.box-slant:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
}

.box-slant-top-left:before {
  z-index: 1;
  top: 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 5.5rem);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 5.5rem);
}

@media only screen and (max-width: 991px) {
  .box-slant-top-left:before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 2.5rem);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 2.5rem);
  }
}

.box-slant-top-left-primary:before {
  background: #3A3C48;
}

.box-slant-top-left-primary-light:before {
  background: #454856;
}

.box-slant-top-left-primary-dark:before {
  background: #141622;
}

.box-slant-top-left-secondary:before {
  background: #992945;
}

.box-slant-top-left-secondary-light:before {
  background: #ad2e4e;
}

.box-slant-top-left-secondary-dark:before {
  background: #6A162B;
}

.box-slant-top-left-tertiary:before {
  background: #7BA7C5;
}

.box-slant-top-left-tertiary-light:before {
  background: #8db3cd;
}

.box-slant-top-left-tertiary-dark:before {
  background: #5D8AA8;
}

.box-slant-top-left-gray:before {
  background: #F5F9FD;
}

.box-slant-top-left-gray-light:before {
  background: white;
}

.box-slant-top-left-gray-dark:before {
  background: #ECF0F3;
}

.box-slant-top-left-gray-darker:before {
  background: #B5B5B5;
}

.box-slant-top-left-white:before {
  background: white;
}

.box-slant-top-left-black:before {
  background: black;
}

.box-slant-top-right:after {
  z-index: 2;
  top: 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 5.5rem, 0 0);
  clip-path: polygon(0 0, 100% 0, 100% 5.5rem, 0 0);
}

@media only screen and (max-width: 991px) {
  .box-slant-top-right:after {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 2.5rem, 0 0);
    clip-path: polygon(0 0, 100% 0, 100% 2.5rem, 0 0);
  }
}

.box-slant-top-right-primary:after {
  background: #3A3C48;
}

.box-slant-top-right-primary-light:after {
  background: #454856;
}

.box-slant-top-right-primary-dark:after {
  background: #141622;
}

.box-slant-top-right-secondary:after {
  background: #992945;
}

.box-slant-top-right-secondary-light:after {
  background: #ad2e4e;
}

.box-slant-top-right-secondary-dark:after {
  background: #6A162B;
}

.box-slant-top-right-tertiary:after {
  background: #7BA7C5;
}

.box-slant-top-right-tertiary-light:after {
  background: #8db3cd;
}

.box-slant-top-right-tertiary-dark:after {
  background: #5D8AA8;
}

.box-slant-top-right-gray:after {
  background: #F5F9FD;
}

.box-slant-top-right-gray-light:after {
  background: white;
}

.box-slant-top-right-gray-dark:after {
  background: #ECF0F3;
}

.box-slant-top-right-gray-darker:after {
  background: #B5B5B5;
}

.box-slant-top-right-white:after {
  background: white;
}

.box-slant-top-right-black:after {
  background: black;
}

.box-slant-bottom-left:before {
  z-index: 2;
  bottom: 0;
  -webkit-clip-path: polygon(0 calc(100% - 5.5rem), 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 calc(100% - 5.5rem), 100% 100%, 100% 100%, 0 100%);
}

@media only screen and (max-width: 991px) {
  .box-slant-bottom-left:before {
    -webkit-clip-path: polygon(0 calc(100% - 2.5rem), 100% 100%, 100% 100%, 0 100%);
    clip-path: polygon(0 calc(100% - 2.5rem), 100% 100%, 100% 100%, 0 100%);
  }
}

.box-slant-bottom-left-primary:before {
  background: #3A3C48;
}

.box-slant-bottom-left-primary-light:before {
  background: #454856;
}

.box-slant-bottom-left-primary-dark:before {
  background: #141622;
}

.box-slant-bottom-left-secondary:before {
  background: #992945;
}

.box-slant-bottom-left-secondary-light:before {
  background: #ad2e4e;
}

.box-slant-bottom-left-secondary-dark:before {
  background: #6A162B;
}

.box-slant-bottom-left-tertiary:before {
  background: #7BA7C5;
}

.box-slant-bottom-left-tertiary-light:before {
  background: #8db3cd;
}

.box-slant-bottom-left-tertiary-dark:before {
  background: #5D8AA8;
}

.box-slant-bottom-left-gray:before {
  background: #F5F9FD;
}

.box-slant-bottom-left-gray-light:before {
  background: white;
}

.box-slant-bottom-left-gray-dark:before {
  background: #ECF0F3;
}

.box-slant-bottom-left-gray-darker:before {
  background: #B5B5B5;
}

.box-slant-bottom-left-white:before {
  background: white;
}

.box-slant-bottom-left-black:before {
  background: black;
}

.box-slant-bottom-right:after {
  z-index: 1;
  bottom: 0;
  -webkit-clip-path: polygon(0 100%, 100% calc(100% - 5.5rem), 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% calc(100% - 5.5rem), 100% 100%, 0 100%);
}

@media only screen and (max-width: 991px) {
  .box-slant-bottom-right:after {
    -webkit-clip-path: polygon(0 100%, 100% calc(100% - 2.5rem), 100% 100%, 0 100%);
    clip-path: polygon(0 100%, 100% calc(100% - 2.5rem), 100% 100%, 0 100%);
  }
}

.box-slant-bottom-right-primary:after {
  background: #3A3C48;
}

.box-slant-bottom-right-primary-light:after {
  background: #454856;
}

.box-slant-bottom-right-primary-dark:after {
  background: #141622;
}

.box-slant-bottom-right-secondary:after {
  background: #992945;
}

.box-slant-bottom-right-secondary-light:after {
  background: #ad2e4e;
}

.box-slant-bottom-right-secondary-dark:after {
  background: #6A162B;
}

.box-slant-bottom-right-tertiary:after {
  background: #7BA7C5;
}

.box-slant-bottom-right-tertiary-light:after {
  background: #8db3cd;
}

.box-slant-bottom-right-tertiary-dark:after {
  background: #5D8AA8;
}

.box-slant-bottom-right-gray:after {
  background: #F5F9FD;
}

.box-slant-bottom-right-gray-light:after {
  background: white;
}

.box-slant-bottom-right-gray-dark:after {
  background: #ECF0F3;
}

.box-slant-bottom-right-gray-darker:after {
  background: #B5B5B5;
}

.box-slant-bottom-right-white:after {
  background: white;
}

.box-slant-bottom-right-black:after {
  background: black;
}

.box-slant img {
  width: 100%;
  margin-top: -6rem;
  margin-bottom: -6rem;
}

@media only screen and (max-width: 991px) {
  .box-slant img {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
}

.case-study {
  height: 200px;
  background: white;
  display: block;
}

.case-study .img {
  height: 120px;
  position: relative;
}

.case-study .img:before {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background: #3A3C48;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 2rem);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 2rem);
}

.case-study .img:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  bottom: -1px;
  position: absolute;
  background: white;
  -webkit-clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
}

.case-study .content {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 2rem;
}

.case-study .content img {
  margin-right: 2rem;
  border-radius: 50%;
  max-height: 50px;
}

.card {
  height: 100%;
  background: white;
  border: 0;
  border-radius: 0;
}

.card .img {
  height: 150px;
  position: relative;
}

.card .img:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background: white;
  -webkit-clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
}

.card .content {
  display: flex;
  align-items: center;
  padding: 2rem;
  color: black;
}

.card .content img {
  margin-right: 2rem;
  border-radius: 50%;
  max-height: 50px;
}

.course-card {
  display: block;
  background: white;
  height: 26rem;
}

@media only screen and (max-width: 991px) {
  .course-card {
    height: auto;
    min-height: 20rem;
  }
}

.course-card > .row {
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .course-card > .row {
    min-height: 20rem;
  }
}

.course-card .img {
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .course-card .img {
    min-height: 15rem;
  }
}

.course-card .content {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .course-card .content h2,
  .course-card .content .cart-item .course-price .price,
  .cart-item .course-price .course-card .content .price,
  .course-card .content .purchase-card .price,
  .purchase-card .course-card .content .price,
  .course-card .content header .details .info p,
  header .details .info .course-card .content p,
  .course-card .content .share p,
  .share .course-card .content p,
  .course-card .content .contact-list-boxed li,
  .contact-list-boxed .course-card .content li {
    font-size: 1.9rem;
    line-height: 2.7rem;
    margin-bottom: 1rem;
  }
}

.course-card .content .details {
  margin-top: auto;
}

.course-card .content .details .info {
  display: inline-block;
}

.course-card .content .details .info h5 {
  color: #7BA7C5;
}

.course-card .content .details .info p {
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  color: black;
}

.course-card .content .details .info + .info {
  margin-left: 5rem;
}

.purchase-card {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .purchase-card {
    padding-top: 3rem;
  }
}

.purchase-card img {
  max-width: 8rem;
  margin-bottom: 3rem;
}

.purchase-card .price {
  color: #992945;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 991px) {
  .purchase-card .button {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 991px) {
  .books .card h2,
  .books .card .cart-item .course-price .price,
  .cart-item .course-price .books .card .price,
  .books .card header .details .info p,
  header .details .info .books .card p,
  .books .card .share p,
  .share .books .card p,
  .books .card .contact-list-boxed li,
  .contact-list-boxed .books .card li,
  .books .card .purchase-card .price,
  .purchase-card .books .card .price {
    font-size: 1.9rem;
    line-height: 2.7rem;
  }
}

.blog-post .content h2,
.blog-post .content .cart-item .course-price .price,
.cart-item .course-price .blog-post .content .price,
.blog-post .content header .details .info p,
header .details .info .blog-post .content p,
.blog-post .content .share p,
.share .blog-post .content p,
.blog-post .content .contact-list-boxed li,
.contact-list-boxed .blog-post .content li,
.blog-post .content .purchase-card .price,
.purchase-card .blog-post .content .price {
  margin-bottom: 3rem;
  color: #7BA7C5;
}

.blog-post .content h3,
.blog-post .content .exam-info .info .value,
.exam-info .info .blog-post .content .value,
.blog-post .content .purchase-card .users,
.purchase-card .blog-post .content .users {
  margin-bottom: 1.5rem;
  color: #7BA7C5;
}

.blog-post .content p + h2,
.blog-post .content .cart-item .course-price p + .price,
.cart-item .course-price .blog-post .content p + .price,
.blog-post .content header .details .info p + p,
header .details .info .blog-post .content p + p,
.blog-post .content .share p + p,
.share .blog-post .content p + p,
.blog-post .content .contact-list-boxed p + li,
.contact-list-boxed .blog-post .content p + li,
.blog-post .content .purchase-card p + .price,
.purchase-card .blog-post .content p + .price,
.blog-post .content p + h3,
.blog-post .content .exam-info .info p + .value,
.exam-info .info .blog-post .content p + .value,
.blog-post .content .purchase-card p + .users,
.purchase-card .blog-post .content p + .users {
  margin-top: 3rem;
}

.img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  overflow: hidden;
}

.feedback-slider {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.slick-slide:focus {
  outline: none;
}

.feedback {
  height: 100%;
  padding: 3rem;
  background: #3A3C48;
  color: white;
  position: relative;
  margin-bottom: 5rem;
  min-height: 30rem;
}

.feedback:after {
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  background: #3A3C48;
  position: absolute;
  bottom: -50px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

.feedback .via-quote {
  width: 3rem !important;
  height: 3rem !important;
  margin-bottom: 2.5rem;
}

.feedback .via-quote:before {
  width: 3rem !important;
  height: 3rem !important;
}

.pagination {
  justify-content: center;
}

.pagination > li > a,
.pagination > li > span {
  color: black;
  border: none;
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
  font-family: "Poppins", sans-serif;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover,
.pagination > li > a:hover,
.pagination > li > span:hover {
  opacity: 1;
  background-color: #992945 !important;
  border-color: #992945 !important;
  color: #FFF;
}

.pagination > li.disabled {
  display: none;
}

.pie-wrapper {
  width: 5rem;
  height: 5rem;
  position: relative;
}

.pie-wrapper .pie {
  width: 100%;
  height: 100%;
  clip: rect(0, 5rem, 5rem, 2.5rem);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper .pie .half-circle {
  width: 100%;
  height: 100%;
  border: 3px solid #992945;
  border-radius: 50%;
  clip: rect(0, 2.5rem, 5rem, 0);
  left: 0;
  position: absolute;
  top: 0;
}

.pie-wrapper .label {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #3A3C48;
  cursor: default;
  font-size: 1.4rem;
  position: absolute;
}

.pie-wrapper .shadow {
  width: 100%;
  height: 100%;
  border: 0.5rem solid #ECF0F3;
  border-radius: 50%;
  box-shadow: none !important;
}

.pie-wrapper.progress-1 .pie .left-side {
  transform: rotate(3.6deg);
}

.pie-wrapper.progress-1 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-2 .pie .left-side {
  transform: rotate(7.2deg);
}

.pie-wrapper.progress-2 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-3 .pie .left-side {
  transform: rotate(10.8deg);
}

.pie-wrapper.progress-3 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-4 .pie .left-side {
  transform: rotate(14.4deg);
}

.pie-wrapper.progress-4 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-5 .pie .left-side {
  transform: rotate(18deg);
}

.pie-wrapper.progress-5 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-6 .pie .left-side {
  transform: rotate(21.6deg);
}

.pie-wrapper.progress-6 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-7 .pie .left-side {
  transform: rotate(25.2deg);
}

.pie-wrapper.progress-7 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-8 .pie .left-side {
  transform: rotate(28.8deg);
}

.pie-wrapper.progress-8 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-9 .pie .left-side {
  transform: rotate(32.4deg);
}

.pie-wrapper.progress-9 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-10 .pie .left-side {
  transform: rotate(36deg);
}

.pie-wrapper.progress-10 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-11 .pie .left-side {
  transform: rotate(39.6deg);
}

.pie-wrapper.progress-11 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-12 .pie .left-side {
  transform: rotate(43.2deg);
}

.pie-wrapper.progress-12 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-13 .pie .left-side {
  transform: rotate(46.8deg);
}

.pie-wrapper.progress-13 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-14 .pie .left-side {
  transform: rotate(50.4deg);
}

.pie-wrapper.progress-14 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-15 .pie .left-side {
  transform: rotate(54deg);
}

.pie-wrapper.progress-15 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-16 .pie .left-side {
  transform: rotate(57.6deg);
}

.pie-wrapper.progress-16 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-17 .pie .left-side {
  transform: rotate(61.2deg);
}

.pie-wrapper.progress-17 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-18 .pie .left-side {
  transform: rotate(64.8deg);
}

.pie-wrapper.progress-18 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-19 .pie .left-side {
  transform: rotate(68.4deg);
}

.pie-wrapper.progress-19 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-20 .pie .left-side {
  transform: rotate(72deg);
}

.pie-wrapper.progress-20 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-21 .pie .left-side {
  transform: rotate(75.6deg);
}

.pie-wrapper.progress-21 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-22 .pie .left-side {
  transform: rotate(79.2deg);
}

.pie-wrapper.progress-22 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-23 .pie .left-side {
  transform: rotate(82.8deg);
}

.pie-wrapper.progress-23 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-24 .pie .left-side {
  transform: rotate(86.4deg);
}

.pie-wrapper.progress-24 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-25 .pie .left-side {
  transform: rotate(90deg);
}

.pie-wrapper.progress-25 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-26 .pie .left-side {
  transform: rotate(93.6deg);
}

.pie-wrapper.progress-26 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-27 .pie .left-side {
  transform: rotate(97.2deg);
}

.pie-wrapper.progress-27 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-28 .pie .left-side {
  transform: rotate(100.8deg);
}

.pie-wrapper.progress-28 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-29 .pie .left-side {
  transform: rotate(104.4deg);
}

.pie-wrapper.progress-29 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-30 .pie .left-side {
  transform: rotate(108deg);
}

.pie-wrapper.progress-30 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-31 .pie .left-side {
  transform: rotate(111.6deg);
}

.pie-wrapper.progress-31 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-32 .pie .left-side {
  transform: rotate(115.2deg);
}

.pie-wrapper.progress-32 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-33 .pie .left-side {
  transform: rotate(118.8deg);
}

.pie-wrapper.progress-33 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-34 .pie .left-side {
  transform: rotate(122.4deg);
}

.pie-wrapper.progress-34 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-35 .pie .left-side {
  transform: rotate(126deg);
}

.pie-wrapper.progress-35 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-36 .pie .left-side {
  transform: rotate(129.6deg);
}

.pie-wrapper.progress-36 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-37 .pie .left-side {
  transform: rotate(133.2deg);
}

.pie-wrapper.progress-37 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-38 .pie .left-side {
  transform: rotate(136.8deg);
}

.pie-wrapper.progress-38 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-39 .pie .left-side {
  transform: rotate(140.4deg);
}

.pie-wrapper.progress-39 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-40 .pie .left-side {
  transform: rotate(144deg);
}

.pie-wrapper.progress-40 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-41 .pie .left-side {
  transform: rotate(147.6deg);
}

.pie-wrapper.progress-41 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-42 .pie .left-side {
  transform: rotate(151.2deg);
}

.pie-wrapper.progress-42 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-43 .pie .left-side {
  transform: rotate(154.8deg);
}

.pie-wrapper.progress-43 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-44 .pie .left-side {
  transform: rotate(158.4deg);
}

.pie-wrapper.progress-44 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-45 .pie .left-side {
  transform: rotate(162deg);
}

.pie-wrapper.progress-45 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-46 .pie .left-side {
  transform: rotate(165.6deg);
}

.pie-wrapper.progress-46 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-47 .pie .left-side {
  transform: rotate(169.2deg);
}

.pie-wrapper.progress-47 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-48 .pie .left-side {
  transform: rotate(172.8deg);
}

.pie-wrapper.progress-48 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-49 .pie .left-side {
  transform: rotate(176.4deg);
}

.pie-wrapper.progress-49 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-50 .pie .left-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-50 .pie .right-side {
  display: none;
}

.pie-wrapper.progress-51 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-51 .pie .left-side {
  transform: rotate(183.6deg);
}

.pie-wrapper.progress-51 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-52 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-52 .pie .left-side {
  transform: rotate(187.2deg);
}

.pie-wrapper.progress-52 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-53 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-53 .pie .left-side {
  transform: rotate(190.8deg);
}

.pie-wrapper.progress-53 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-54 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-54 .pie .left-side {
  transform: rotate(194.4deg);
}

.pie-wrapper.progress-54 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-55 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-55 .pie .left-side {
  transform: rotate(198deg);
}

.pie-wrapper.progress-55 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-56 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-56 .pie .left-side {
  transform: rotate(201.6deg);
}

.pie-wrapper.progress-56 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-57 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-57 .pie .left-side {
  transform: rotate(205.2deg);
}

.pie-wrapper.progress-57 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-58 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-58 .pie .left-side {
  transform: rotate(208.8deg);
}

.pie-wrapper.progress-58 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-59 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-59 .pie .left-side {
  transform: rotate(212.4deg);
}

.pie-wrapper.progress-59 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-60 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-60 .pie .left-side {
  transform: rotate(216deg);
}

.pie-wrapper.progress-60 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-61 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-61 .pie .left-side {
  transform: rotate(219.6deg);
}

.pie-wrapper.progress-61 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-62 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-62 .pie .left-side {
  transform: rotate(223.2deg);
}

.pie-wrapper.progress-62 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-63 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-63 .pie .left-side {
  transform: rotate(226.8deg);
}

.pie-wrapper.progress-63 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-64 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-64 .pie .left-side {
  transform: rotate(230.4deg);
}

.pie-wrapper.progress-64 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-65 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-65 .pie .left-side {
  transform: rotate(234deg);
}

.pie-wrapper.progress-65 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-66 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-66 .pie .left-side {
  transform: rotate(237.6deg);
}

.pie-wrapper.progress-66 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-67 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-67 .pie .left-side {
  transform: rotate(241.2deg);
}

.pie-wrapper.progress-67 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-68 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-68 .pie .left-side {
  transform: rotate(244.8deg);
}

.pie-wrapper.progress-68 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-69 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-69 .pie .left-side {
  transform: rotate(248.4deg);
}

.pie-wrapper.progress-69 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-70 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-70 .pie .left-side {
  transform: rotate(252deg);
}

.pie-wrapper.progress-70 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-71 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-71 .pie .left-side {
  transform: rotate(255.6deg);
}

.pie-wrapper.progress-71 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-72 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-72 .pie .left-side {
  transform: rotate(259.2deg);
}

.pie-wrapper.progress-72 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-73 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-73 .pie .left-side {
  transform: rotate(262.8deg);
}

.pie-wrapper.progress-73 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-74 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-74 .pie .left-side {
  transform: rotate(266.4deg);
}

.pie-wrapper.progress-74 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-75 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-75 .pie .left-side {
  transform: rotate(270deg);
}

.pie-wrapper.progress-75 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-76 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-76 .pie .left-side {
  transform: rotate(273.6deg);
}

.pie-wrapper.progress-76 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-77 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-77 .pie .left-side {
  transform: rotate(277.2deg);
}

.pie-wrapper.progress-77 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-78 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-78 .pie .left-side {
  transform: rotate(280.8deg);
}

.pie-wrapper.progress-78 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-79 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-79 .pie .left-side {
  transform: rotate(284.4deg);
}

.pie-wrapper.progress-79 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-80 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-80 .pie .left-side {
  transform: rotate(288deg);
}

.pie-wrapper.progress-80 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-81 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-81 .pie .left-side {
  transform: rotate(291.6deg);
}

.pie-wrapper.progress-81 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-82 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-82 .pie .left-side {
  transform: rotate(295.2deg);
}

.pie-wrapper.progress-82 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-83 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-83 .pie .left-side {
  transform: rotate(298.8deg);
}

.pie-wrapper.progress-83 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-84 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-84 .pie .left-side {
  transform: rotate(302.4deg);
}

.pie-wrapper.progress-84 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-85 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-85 .pie .left-side {
  transform: rotate(306deg);
}

.pie-wrapper.progress-85 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-86 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-86 .pie .left-side {
  transform: rotate(309.6deg);
}

.pie-wrapper.progress-86 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-87 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-87 .pie .left-side {
  transform: rotate(313.2deg);
}

.pie-wrapper.progress-87 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-88 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-88 .pie .left-side {
  transform: rotate(316.8deg);
}

.pie-wrapper.progress-88 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-89 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-89 .pie .left-side {
  transform: rotate(320.4deg);
}

.pie-wrapper.progress-89 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-90 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-90 .pie .left-side {
  transform: rotate(324deg);
}

.pie-wrapper.progress-90 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-91 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-91 .pie .left-side {
  transform: rotate(327.6deg);
}

.pie-wrapper.progress-91 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-92 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-92 .pie .left-side {
  transform: rotate(331.2deg);
}

.pie-wrapper.progress-92 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-93 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-93 .pie .left-side {
  transform: rotate(334.8deg);
}

.pie-wrapper.progress-93 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-94 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-94 .pie .left-side {
  transform: rotate(338.4deg);
}

.pie-wrapper.progress-94 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-95 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-95 .pie .left-side {
  transform: rotate(342deg);
}

.pie-wrapper.progress-95 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-96 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-96 .pie .left-side {
  transform: rotate(345.6deg);
}

.pie-wrapper.progress-96 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-97 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-97 .pie .left-side {
  transform: rotate(349.2deg);
}

.pie-wrapper.progress-97 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-98 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-98 .pie .left-side {
  transform: rotate(352.8deg);
}

.pie-wrapper.progress-98 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-99 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-99 .pie .left-side {
  transform: rotate(356.4deg);
}

.pie-wrapper.progress-99 .pie .right-side {
  transform: rotate(180deg);
}

.pie-wrapper.progress-100 .pie {
  clip: rect(auto, auto, auto, auto);
}

.pie-wrapper.progress-100 .pie .left-side {
  transform: rotate(360deg);
}

.pie-wrapper.progress-100 .pie .right-side {
  transform: rotate(180deg);
}

.cart-item {
  padding: 3rem;
  margin-top: 3rem;
  background-color: #F5F9FD;
}

@media only screen and (max-width: 991px) {
  .cart-item {
    padding: 2rem;
  }
}

.cart-item h2,
.cart-item .course-price .price,
.cart-item header .details .info p,
header .details .info .cart-item p,
.cart-item .share p,
.share .cart-item p,
.cart-item .contact-list-boxed li,
.contact-list-boxed .cart-item li,
.cart-item .purchase-card .price,
.purchase-card .cart-item .price {
  margin-bottom: 3rem;
}

.cart-item h3,
.cart-item .exam-info .info .value,
.exam-info .info .cart-item .value,
.cart-item .purchase-card .users,
.purchase-card .cart-item .users {
  color: #7BA7C5;
}

@media only screen and (max-width: 991px) {
  .cart-item .buttons {
    flex-direction: column-reverse;
  }
}

.cart-item .course-price {
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .cart-item .course-price {
    text-align: left;
  }
}

.cart-item .course-price .price span {
  color: #7BA7C5;
}

@media only screen and (min-width: 992px) {
  .cart-item .course-price .price span {
    display: none;
  }
}

.cart-item .quantity form {
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .cart-item .quantity form {
    display: inline-flex;
  }
}

.cart-item .quantity form label {
  background: white;
  width: auto;
  padding-left: 2rem;
}

@media only screen and (max-width: 991px) {
  .cart-item .quantity form label {
    width: 60%;
  }
}

.cart-item .quantity form label input {
  margin: 0;
  border-color: transparent;
}

@media only screen and (max-width: 991px) {
  .cart-item .quantity form label input {
    width: calc(100% - 50px);
  }
}

.cart-item .quantity form button {
  margin-left: 1rem;
}

@media only screen and (max-width: 991px) {
  .cart-item .quantity form button {
    padding: 0 1rem;
    width: calc(40% - 1.99rem);
    margin-left: auto;
    min-width: 0;
  }
}

.cart-total {
  position: sticky;
  top: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  margin-top: 3rem;
  background-color: #F5F9FD;
}

@media only screen and (max-width: 991px) {
  .cart-total {
    padding: 2rem;
  }
}

.cart-total h3,
.cart-total .exam-info .info .value,
.exam-info .info .cart-total .value,
.cart-total .purchase-card .users,
.purchase-card .cart-total .users {
  color: #7BA7C5;
  margin-bottom: 2rem;
}

.cart-total h3 .price,
.cart-total .exam-info .info .value .price,
.exam-info .info .cart-total .value .price,
.cart-total .purchase-card .users .price,
.purchase-card .cart-total .users .price {
  color: black;
  margin-left: 2rem;
}

.cart-total .button {
  margin-top: 1rem;
}

.cart-total .voucher-button {
  margin-top: 0;
  margin-left: -1.5rem;
  min-width: 0;
  width: calc(100% + 1.5rem);
  padding: 0 1rem;
}

.cart-empty img {
  background-color: #F5F9FD;
  border-radius: 50%;
  width: 15rem;
  margin-bottom: 5rem;
  padding: 2rem;
}

.cart-info {
  padding: 3rem;
  margin-bottom: 0.25rem;
  background-color: #F5F9FD;
}

@media only screen and (max-width: 991px) {
  .cart-info {
    padding: 2rem;
  }
}

.cart-info:first-of-type {
  margin-top: 3rem;
}

.cart-info h4 {
  color: #B5B5B5;
}

.cart-info p + h4 {
  margin-top: 2rem;
}

.cart-info .form-control.StripeElement,
.cart-info .cart-item .quantity form label input.StripeElement,
.cart-item .quantity form label .cart-info input.StripeElement {
  padding-top: 2rem;
}

.cart-info .edit-address.hide,
.cart-info .address-text.hide {
  display: none;
}

.cart-info .address-form {
  display: none;
}

.cart-info .address-form.show {
  display: block;
}

.order-item {
  margin-top: 3rem;
  background-color: white;
}

.order-item .content {
  padding: 3rem;
}

.order-item .content h2,
.order-item .content header .details .info p,
header .details .info .order-item .content p,
.order-item .content .share p,
.share .order-item .content p,
.order-item .content .contact-list-boxed li,
.contact-list-boxed .order-item .content li,
.order-item .content .purchase-card .price,
.purchase-card .order-item .content .price,
.order-item .content .cart-item .course-price .price,
.cart-item .course-price .order-item .content .price {
  margin-bottom: 3rem;
}

.order-item .content h3,
.order-item .content .exam-info .info .value,
.exam-info .info .order-item .content .value,
.order-item .content .purchase-card .users,
.purchase-card .order-item .content .users {
  color: #7BA7C5;
  display: inline-block;
}

.order-item .content h3:not(:first-of-type),
.order-item .content .exam-info .info .value:not(:first-of-type),
.exam-info .info .order-item .content .value:not(:first-of-type),
.order-item .content .purchase-card .users:not(:first-of-type),
.purchase-card .order-item .content .users:not(:first-of-type) {
  margin-left: 3rem;
}

.order-item .content h3 .price,
.order-item .content .exam-info .info .value .price,
.exam-info .info .order-item .content .value .price,
.order-item .content .purchase-card .users .price,
.purchase-card .order-item .content .users .price {
  color: black;
  margin-left: 1rem;
}

.order-item .img {
  height: 100%;
}

.modal .modal-body {
  background: #F5F9FD;
  border-radius: 0;
  padding: 3rem;
  display: flex;
  flex-direction: column;
}

.modal .modal-body .button {
  width: 100%;
}

.code-activate h2,
.code-activate header .details .info p,
header .details .info .code-activate p,
.code-activate .share p,
.share .code-activate p,
.code-activate .contact-list-boxed li,
.contact-list-boxed .code-activate li,
.code-activate .purchase-card .price,
.purchase-card .code-activate .price,
.code-activate .cart-item .course-price .price,
.cart-item .course-price .code-activate .price {
  color: white;
  margin-bottom: 3rem;
}

.code-activate form {
  width: 30rem;
}

.code-activate form .button {
  width: 100%;
}

.history:before {
  content: "";
  display: block;
  height: 96%;
  position: absolute;
  top: 2%;
  left: 50%;
  margin-left: -5px;
  border-left: 1rem dotted #454856;
  z-index: 0;
}

.history > div:not(:first-child) {
  margin-top: -12rem;
}

@media only screen and (max-width: 991px) {
  .history > div:not(:first-child) {
    margin-top: 5rem;
  }
}

.history > div:first-child:before,
.history > div:last-child:before {
  content: "";
  display: block;
  position: absolute;
  left: calc(100% - 25px);
  background: #3A3C48;
  width: 50px;
  height: calc(50% + 3rem);
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .history > div:first-child:before,
  .history > div:last-child:before {
    display: none;
  }
}

.history > div:first-child:before {
  top: -2rem;
}

.history > div:last-child:before {
  bottom: -5rem;
  left: auto;
  right: -25px;
}

.history .left .box-slant,
.history .right .box-slant {
  min-height: 300px;
}

.history .left .card:before,
.history .right .card:before {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  top: calc(50% - 10px);
}

.history .left .card:after,
.history .right .card:after {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 20px 0 20px 25px;
  background: #992945;
  content: "";
  position: absolute;
  top: 50%;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .history .left .card:before,
  .history .left .card:after,
  .history .right .card:before,
  .history .right .card:after {
    display: none;
  }
}

.history .left .card:before {
  left: 100%;
  border-width: 20px 0 20px 25px;
  border-color: transparent transparent transparent white;
}

.history .left .card:after {
  left: calc(100% + 55px);
}

.history .right {
  margin-left: 50%;
}

@media only screen and (max-width: 991px) {
  .history .right {
    margin-left: 0;
  }
}

.history .right .card:before {
  right: 100%;
  border-width: 20px 25px 20px 0;
  border-color: transparent white transparent transparent;
}

.history .right .card:after {
  right: calc(100% + 55px);
}

.history .timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.history .timeline:after {
  content: "";
  position: absolute;
  width: 6px;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.awards {
  margin-top: -45rem;
}

@media only screen and (max-width: 991px) {
  .awards {
    margin-top: -55rem;
    overflow-x: hidden;
  }
}

.awards .card {
  z-index: 3;
  color: black;
  height: auto;
}

.awards .card img {
  max-width: 25rem;
  margin: 0 auto 2rem;
}

.awards .card h3,
.awards .card .exam-info .info .value,
.exam-info .info .awards .card .value,
.awards .card .purchase-card .users,
.purchase-card .awards .card .users {
  color: #7BA7C5;
  margin-bottom: 2rem;
}

.awards .card hr {
  margin: 3rem -5rem;
}

.awards .card .author {
  display: flex;
  margin-top: 3rem;
}

.awards .card .author .img-round {
  width: 9rem;
  height: 9rem;
}

.awards .card .author .content {
  flex-direction: column;
  padding: 0;
  padding-left: 2rem;
  align-items: flex-start;
  justify-content: center;
}

.awards .card .author .content h3,
.awards .card .author .content .exam-info .info .value,
.exam-info .info .awards .card .author .content .value,
.awards .card .author .content .purchase-card .users,
.purchase-card .awards .card .author .content .users,
.awards .card .author .content p {
  margin-bottom: 0;
}

.course header {
  background: #3A3C48;
  color: white;
  padding: 2rem;
  font-weight: bold;
  height: 7.5rem;
  min-height: auto;
  justify-content: flex-start;
  align-items: center;
}

.course header:before {
  display: none;
}

@media only screen and (max-width: 991px) {
  .course header .ml-auto .button {
    padding: 0;
    display: none;
  }
}

.course section {
  padding: 0;
  background: white;
}

.course aside {
  background: #F5F9FD;
  position: absolute;
  z-index: 5;
  height: calc(100vh - 7rem);
  padding: 0;
  left: -33%;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

@media only screen and (max-width: 991px) {
  .course aside {
    left: -100%;
    height: 100%;
  }
}

.course aside.show {
  left: 0;
}

.course aside .content {
  height: calc(100% - 7rem);
  overflow-y: auto;
  padding: 2rem;
}

.course aside .content h3,
.course aside .content .exam-info .info .value,
.exam-info .info .course aside .content .value,
.course aside .content .purchase-card .users,
.purchase-card .course aside .content .users {
  color: #992945;
  font-weight: 500;
  font-size: 1.7rem;
}

.course aside .content h3:not(:first-of-type),
.course aside .content .exam-info .info .value:not(:first-of-type),
.exam-info .info .course aside .content .value:not(:first-of-type),
.course aside .content .purchase-card .users:not(:first-of-type),
.purchase-card .course aside .content .users:not(:first-of-type) {
  border-top: 1px solid #B5B5B5;
  padding: 2rem 2rem 0px;
  margin: 3rem -2rem 2rem;
}

.course aside .content .course-page-list {
  list-style: none;
  padding: 0;
}

.course aside .content .course-page-list li.checkbox {
  margin-bottom: 1rem;
}

.course aside .content .course-page-list li.checkbox input:before {
  top: 3px;
  border: 1px solid #B5B5B5;
  background-color: white;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.course aside .content .course-page-list li.checkbox input:checked:before {
  background-color: #7BA7C5;
}

.course aside .content .course-page-list li.checkbox a {
  color: black;
  font-weight: 500;
  font-size: 1.4rem;
}

.course aside .content .course-page-list li.checkbox a:hover {
  color: #992945;
  text-decoration: none;
}

.course aside .content .course-page-list li.checkbox.active a {
  text-decoration: underline;
}

.course article {
  padding: 2rem;
  height: calc(100vh - 7.5rem);
  z-index: 1;
  -webkit-transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -ms-transition: 0.5s ease all;
  -o-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.course article .content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 20rem);
  padding: 3rem 3rem 0;
}

@media only screen and (max-width: 991px) {
  .course article .content {
    padding: 0;
  }
}

.course article .content .text {
  overflow-y: auto;
}

@media only screen and (max-width: 991px) {
  .course article .content .text {
    padding-bottom: 6rem;
  }
}

.course article .content h2,
.course article .content header .details .info p,
header .details .info .course article .content p,
.course article .content .share p,
.share .course article .content p,
.course article .content .contact-list-boxed li,
.contact-list-boxed .course article .content li,
.course article .content .purchase-card .price,
.purchase-card .course article .content .price,
.course article .content .cart-item .course-price .price,
.cart-item .course-price .course article .content .price {
  color: #7BA7C5;
  margin-bottom: 2rem;
}

.course article .content img {
  margin: 0rem 2rem 2rem;
  object-fit: cover;
}

.course article .content p {
  margin-bottom: 2rem;
}

.course article .content table {
  margin-bottom: 2rem;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .course article .content table {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

.course article .content table tr {
  vertical-align: top;
}

@media only screen and (max-width: 991px) {
  .course article .content table tr {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

.course article .content table tr td {
  width: 10% !important;
}

@media only screen and (max-width: 991px) {
  .course article .content table tr td {
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

.course article .content table tr td > img {
  max-width: 100%;
  margin: 0 2rem 2rem;
}

.course article .content table tr td * {
  font-size: 1.7rem !important;
}

.course article .content table tr td p img {
  margin-bottom: 0;
}

.course article .content table tr td li {
  margin-bottom: 1rem;
}

.course article .content .course-length {
  background: #F5F9FD;
  margin-top: 3rem;
  padding: 2rem 3rem;
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .course article .content .course-length {
    width: 100%;
  }
}

.course article .content .course-length dl {
  margin-bottom: 0;
}

.course article .content .course-length dl dt {
  font-weight: 400;
  display: inline-block;
  min-width: 22rem;
}

@media only screen and (max-width: 991px) {
  .course article .content .course-length dl dt {
    min-width: 20rem;
  }
}

.course article .content .course-length dl dd {
  font-weight: 600;
  display: inline-block;
  color: #7BA7C5;
}

.course article footer {
  justify-content: flex-end;
  padding: 0;
  border: 0;
  position: fixed;
  width: 100vw;
  height: 11rem;
  left: 50%;
  right: 50%;
  bottom: 0;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media only screen and (max-width: 991px) {
  .course article footer form {
    position: absolute;
  }
}

.course article footer .page-count {
  position: absolute;
  left: 0;
  width: 100vw;
  margin-top: 3rem;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .course article footer .button {
    padding: 0 1rem;
    min-width: 0;
  }
}

.course .slant {
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .course .slant {
    bottom: 0;
    position: fixed;
  }
}

.course .exam-container {
  padding: 0 0 3rem;
}

.course .question-title {
  color: #7BA7C5;
  font-weight: 600;
}

.course .question-subtitle {
  font-size: 1.6rem;
  color: #B5B5B5;
  margin-bottom: 3rem;
}

.course .question-content {
  margin-bottom: 3rem;
}

.course .next-question:disabled {
  opacity: 0.7;
  cursor: auto;
}

.course .question-count {
  font-weight: bold;
  bottom: 0;
  height: 5rem;
  line-height: 5rem;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  pointer-events: none;
}

.course .timer {
  font-weight: bold;
  height: 11rem;
  line-height: 5rem;
  position: fixed;
  bottom: 0;
  left: 2rem;
  display: flex;
  align-items: center;
}

.course .timer span {
  display: inline-block;
  margin-left: 1rem;
  color: #7BA7C5;
}

.course .pull-right {
  float: right;
}

.course .return-link {
  color: #B5B5B5;
}

.course .return-link:hover {
  color: #B5B5B5;
  opacity: 0.5;
}

.course .question-answers {
  list-style-type: upper-alpha;
  list-style-image: none;
  max-height: 35rem;
  overflow-y: scroll;
}

.course .question-answers li {
  font-weight: 600;
  position: relative;
}

.course .question-answers li:hover {
  color: #7BA7C5;
  background: #F5F9FD;
}

.course .question-answers li:hover:before {
  content: "";
  display: block;
  width: 4rem;
  height: 100%;
  background: #F5F9FD;
  position: absolute;
  margin-left: -4rem;
  z-index: -1;
}

.course .question-answers li:hover label {
  color: #B5B5B5;
}

.course .question-answers li label {
  margin-bottom: 0;
  margin-left: -4rem;
  padding: 2rem 5rem;
  font-weight: 400;
  overflow: hidden;
}

.course .question-answers li label input {
  top: 2rem;
  right: 0;
}

.course .question-answers li label input:before {
  border-color: #992945;
  left: auto;
  right: 2rem;
  top: 0;
}

.course .question-answers li label input:focus:before,
.course .question-answers li label input:hover:before,
.course .question-answers li label input:checked:before {
  background-color: #992945;
}

.course .question-answers li label input:focus:before,
.course .question-answers li label input:hover:before {
  border-color: #6A162B;
}

.course .question-answers li label input:checked:after {
  content: "";
  display: block;
  background-color: #F5F9FD;
  width: 150vw;
  height: 150vw;
  left: -100vw;
  top: -5rem;
  z-index: -1;
  position: absolute;
  visibility: visible;
}

.course .exam-answers {
  max-height: 35rem;
  overflow-y: scroll;
}

.courses-title {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .courses-title {
    flex-direction: column;
  }
}

.courses-title h1,
.courses-title h2.large,
.courses-title header .details .info p.large,
header .details .info .courses-title p.large,
.courses-title .share p.large,
.share .courses-title p.large,
.courses-title .contact-list-boxed li.large,
.contact-list-boxed .courses-title li.large,
.courses-title .purchase-card .large.price,
.purchase-card .courses-title .large.price,
.courses-title .cart-item .course-price .large.price,
.cart-item .course-price .courses-title .large.price {
  display: inline-block;
}

#progress-indicator {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background: white;
  padding: 1rem;
  font-weight: bold;
}

#progress-indicator h3,
#progress-indicator .exam-info .info .value,
.exam-info .info #progress-indicator .value,
#progress-indicator .purchase-card .users,
.purchase-card #progress-indicator .users {
  color: #7BA7C5;
  margin: 0;
}

#progress-indicator .col-md-3 {
  text-align: right;
}

.progress-circle {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.progress-circle:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #F5F9FD;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-circle > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress-circle .progress-left {
  left: 0;
}

.progress-circle .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #992945;
}

.progress-circle .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress-circle .progress-right {
  right: 0;
}

.progress-circle .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress-circle .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 1.4rem;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 300;
}

/* This for loop creates the    necessary css animation names
Due to the split circle of progress-left and progress right, we must use the animations on each side.
*/

.progress-circle[data-percentage="1"] .progress-right .progress-bar {
  animation: loading-1 0.2s linear forwards;
}

.progress-circle[data-percentage="1"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="2"] .progress-right .progress-bar {
  animation: loading-2 0.2s linear forwards;
}

.progress-circle[data-percentage="2"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="3"] .progress-right .progress-bar {
  animation: loading-3 0.2s linear forwards;
}

.progress-circle[data-percentage="3"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="4"] .progress-right .progress-bar {
  animation: loading-4 0.2s linear forwards;
}

.progress-circle[data-percentage="4"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="5"] .progress-right .progress-bar {
  animation: loading-5 0.2s linear forwards;
}

.progress-circle[data-percentage="5"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="6"] .progress-right .progress-bar {
  animation: loading-6 0.2s linear forwards;
}

.progress-circle[data-percentage="6"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="7"] .progress-right .progress-bar {
  animation: loading-7 0.2s linear forwards;
}

.progress-circle[data-percentage="7"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="8"] .progress-right .progress-bar {
  animation: loading-8 0.2s linear forwards;
}

.progress-circle[data-percentage="8"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="9"] .progress-right .progress-bar {
  animation: loading-9 0.2s linear forwards;
}

.progress-circle[data-percentage="9"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-10 0.2s linear forwards;
}

.progress-circle[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="11"] .progress-right .progress-bar {
  animation: loading-11 0.2s linear forwards;
}

.progress-circle[data-percentage="11"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="12"] .progress-right .progress-bar {
  animation: loading-12 0.2s linear forwards;
}

.progress-circle[data-percentage="12"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="13"] .progress-right .progress-bar {
  animation: loading-13 0.2s linear forwards;
}

.progress-circle[data-percentage="13"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="14"] .progress-right .progress-bar {
  animation: loading-14 0.2s linear forwards;
}

.progress-circle[data-percentage="14"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="15"] .progress-right .progress-bar {
  animation: loading-15 0.2s linear forwards;
}

.progress-circle[data-percentage="15"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="16"] .progress-right .progress-bar {
  animation: loading-16 0.2s linear forwards;
}

.progress-circle[data-percentage="16"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="17"] .progress-right .progress-bar {
  animation: loading-17 0.2s linear forwards;
}

.progress-circle[data-percentage="17"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="18"] .progress-right .progress-bar {
  animation: loading-18 0.2s linear forwards;
}

.progress-circle[data-percentage="18"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="19"] .progress-right .progress-bar {
  animation: loading-19 0.2s linear forwards;
}

.progress-circle[data-percentage="19"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-20 0.2s linear forwards;
}

.progress-circle[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="21"] .progress-right .progress-bar {
  animation: loading-21 0.2s linear forwards;
}

.progress-circle[data-percentage="21"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="22"] .progress-right .progress-bar {
  animation: loading-22 0.2s linear forwards;
}

.progress-circle[data-percentage="22"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="23"] .progress-right .progress-bar {
  animation: loading-23 0.2s linear forwards;
}

.progress-circle[data-percentage="23"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="24"] .progress-right .progress-bar {
  animation: loading-24 0.2s linear forwards;
}

.progress-circle[data-percentage="24"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="25"] .progress-right .progress-bar {
  animation: loading-25 0.2s linear forwards;
}

.progress-circle[data-percentage="25"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="26"] .progress-right .progress-bar {
  animation: loading-26 0.2s linear forwards;
}

.progress-circle[data-percentage="26"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="27"] .progress-right .progress-bar {
  animation: loading-27 0.2s linear forwards;
}

.progress-circle[data-percentage="27"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="28"] .progress-right .progress-bar {
  animation: loading-28 0.2s linear forwards;
}

.progress-circle[data-percentage="28"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="29"] .progress-right .progress-bar {
  animation: loading-29 0.2s linear forwards;
}

.progress-circle[data-percentage="29"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-30 0.2s linear forwards;
}

.progress-circle[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="31"] .progress-right .progress-bar {
  animation: loading-31 0.2s linear forwards;
}

.progress-circle[data-percentage="31"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="32"] .progress-right .progress-bar {
  animation: loading-32 0.2s linear forwards;
}

.progress-circle[data-percentage="32"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="33"] .progress-right .progress-bar {
  animation: loading-33 0.2s linear forwards;
}

.progress-circle[data-percentage="33"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="34"] .progress-right .progress-bar {
  animation: loading-34 0.2s linear forwards;
}

.progress-circle[data-percentage="34"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="35"] .progress-right .progress-bar {
  animation: loading-35 0.2s linear forwards;
}

.progress-circle[data-percentage="35"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="36"] .progress-right .progress-bar {
  animation: loading-36 0.2s linear forwards;
}

.progress-circle[data-percentage="36"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="37"] .progress-right .progress-bar {
  animation: loading-37 0.2s linear forwards;
}

.progress-circle[data-percentage="37"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="38"] .progress-right .progress-bar {
  animation: loading-38 0.2s linear forwards;
}

.progress-circle[data-percentage="38"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="39"] .progress-right .progress-bar {
  animation: loading-39 0.2s linear forwards;
}

.progress-circle[data-percentage="39"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-40 0.2s linear forwards;
}

.progress-circle[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="41"] .progress-right .progress-bar {
  animation: loading-41 0.2s linear forwards;
}

.progress-circle[data-percentage="41"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="42"] .progress-right .progress-bar {
  animation: loading-42 0.2s linear forwards;
}

.progress-circle[data-percentage="42"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="43"] .progress-right .progress-bar {
  animation: loading-43 0.2s linear forwards;
}

.progress-circle[data-percentage="43"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="44"] .progress-right .progress-bar {
  animation: loading-44 0.2s linear forwards;
}

.progress-circle[data-percentage="44"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="45"] .progress-right .progress-bar {
  animation: loading-45 0.2s linear forwards;
}

.progress-circle[data-percentage="45"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="46"] .progress-right .progress-bar {
  animation: loading-46 0.2s linear forwards;
}

.progress-circle[data-percentage="46"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="47"] .progress-right .progress-bar {
  animation: loading-47 0.2s linear forwards;
}

.progress-circle[data-percentage="47"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="48"] .progress-right .progress-bar {
  animation: loading-48 0.2s linear forwards;
}

.progress-circle[data-percentage="48"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="49"] .progress-right .progress-bar {
  animation: loading-49 0.2s linear forwards;
}

.progress-circle[data-percentage="49"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress-circle[data-percentage="51"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="51"] .progress-left .progress-bar {
  animation: loading-1 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="52"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="52"] .progress-left .progress-bar {
  animation: loading-2 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="53"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="53"] .progress-left .progress-bar {
  animation: loading-3 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="54"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="54"] .progress-left .progress-bar {
  animation: loading-4 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="55"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="55"] .progress-left .progress-bar {
  animation: loading-5 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="56"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="56"] .progress-left .progress-bar {
  animation: loading-6 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="57"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="57"] .progress-left .progress-bar {
  animation: loading-7 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="58"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="58"] .progress-left .progress-bar {
  animation: loading-8 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="59"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="59"] .progress-left .progress-bar {
  animation: loading-9 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-10 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="61"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="61"] .progress-left .progress-bar {
  animation: loading-11 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="62"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="62"] .progress-left .progress-bar {
  animation: loading-12 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="63"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="63"] .progress-left .progress-bar {
  animation: loading-13 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="64"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="64"] .progress-left .progress-bar {
  animation: loading-14 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="65"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="65"] .progress-left .progress-bar {
  animation: loading-15 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="66"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="66"] .progress-left .progress-bar {
  animation: loading-16 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="67"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="67"] .progress-left .progress-bar {
  animation: loading-17 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="68"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="68"] .progress-left .progress-bar {
  animation: loading-18 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="69"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="69"] .progress-left .progress-bar {
  animation: loading-19 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-20 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="71"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="71"] .progress-left .progress-bar {
  animation: loading-21 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="72"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="72"] .progress-left .progress-bar {
  animation: loading-22 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="73"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="73"] .progress-left .progress-bar {
  animation: loading-23 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="74"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="74"] .progress-left .progress-bar {
  animation: loading-24 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="75"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="75"] .progress-left .progress-bar {
  animation: loading-25 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="76"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="76"] .progress-left .progress-bar {
  animation: loading-26 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="77"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="77"] .progress-left .progress-bar {
  animation: loading-27 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="78"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="78"] .progress-left .progress-bar {
  animation: loading-28 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="79"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="79"] .progress-left .progress-bar {
  animation: loading-29 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-30 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="81"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="81"] .progress-left .progress-bar {
  animation: loading-31 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="82"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="82"] .progress-left .progress-bar {
  animation: loading-32 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="83"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="83"] .progress-left .progress-bar {
  animation: loading-33 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="84"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="84"] .progress-left .progress-bar {
  animation: loading-34 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="85"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="85"] .progress-left .progress-bar {
  animation: loading-35 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="86"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="86"] .progress-left .progress-bar {
  animation: loading-36 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="87"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="87"] .progress-left .progress-bar {
  animation: loading-37 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="88"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="88"] .progress-left .progress-bar {
  animation: loading-38 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="89"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="89"] .progress-left .progress-bar {
  animation: loading-39 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-40 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="91"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="91"] .progress-left .progress-bar {
  animation: loading-41 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="92"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="92"] .progress-left .progress-bar {
  animation: loading-42 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="93"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="93"] .progress-left .progress-bar {
  animation: loading-43 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="94"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="94"] .progress-left .progress-bar {
  animation: loading-44 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="95"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="95"] .progress-left .progress-bar {
  animation: loading-45 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="96"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="96"] .progress-left .progress-bar {
  animation: loading-46 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="97"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="97"] .progress-left .progress-bar {
  animation: loading-47 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="98"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="98"] .progress-left .progress-bar {
  animation: loading-48 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="99"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="99"] .progress-left .progress-bar {
  animation: loading-49 0.2s linear forwards 0.2s;
}

.progress-circle[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-50 0.2s linear forwards;
}

.progress-circle[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-50 0.2s linear forwards 0.2s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(3.6);
    transform: rotate(3.6deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(7.2);
    transform: rotate(7.2deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(10.8);
    transform: rotate(10.8deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(14.4);
    transform: rotate(14.4deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(18);
    transform: rotate(18deg);
  }
}

@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(21.6);
    transform: rotate(21.6deg);
  }
}

@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(25.2);
    transform: rotate(25.2deg);
  }
}

@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(28.8);
    transform: rotate(28.8deg);
  }
}

@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(32.4);
    transform: rotate(32.4deg);
  }
}

@keyframes loading-10 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}

@keyframes loading-11 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(39.6);
    transform: rotate(39.6deg);
  }
}

@keyframes loading-12 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(43.2);
    transform: rotate(43.2deg);
  }
}

@keyframes loading-13 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(46.8);
    transform: rotate(46.8deg);
  }
}

@keyframes loading-14 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(50.4);
    transform: rotate(50.4deg);
  }
}

@keyframes loading-15 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(54);
    transform: rotate(54deg);
  }
}

@keyframes loading-16 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(57.6);
    transform: rotate(57.6deg);
  }
}

@keyframes loading-17 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(61.2);
    transform: rotate(61.2deg);
  }
}

@keyframes loading-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(64.8);
    transform: rotate(64.8deg);
  }
}

@keyframes loading-19 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(68.4);
    transform: rotate(68.4deg);
  }
}

@keyframes loading-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}

@keyframes loading-21 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(75.6);
    transform: rotate(75.6deg);
  }
}

@keyframes loading-22 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(79.2);
    transform: rotate(79.2deg);
  }
}

@keyframes loading-23 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(82.8);
    transform: rotate(82.8deg);
  }
}

@keyframes loading-24 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(86.4);
    transform: rotate(86.4deg);
  }
}

@keyframes loading-25 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90);
    transform: rotate(90deg);
  }
}

@keyframes loading-26 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(93.6);
    transform: rotate(93.6deg);
  }
}

@keyframes loading-27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(97.2);
    transform: rotate(97.2deg);
  }
}

@keyframes loading-28 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(100.8);
    transform: rotate(100.8deg);
  }
}

@keyframes loading-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(104.4);
    transform: rotate(104.4deg);
  }
}

@keyframes loading-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}

@keyframes loading-31 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(111.6);
    transform: rotate(111.6deg);
  }
}

@keyframes loading-32 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(115.2);
    transform: rotate(115.2deg);
  }
}

@keyframes loading-33 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(118.8);
    transform: rotate(118.8deg);
  }
}

@keyframes loading-34 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(122.4);
    transform: rotate(122.4deg);
  }
}

@keyframes loading-35 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(126);
    transform: rotate(126deg);
  }
}

@keyframes loading-36 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(129.6);
    transform: rotate(129.6deg);
  }
}

@keyframes loading-37 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(133.2);
    transform: rotate(133.2deg);
  }
}

@keyframes loading-38 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(136.8);
    transform: rotate(136.8deg);
  }
}

@keyframes loading-39 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(140.4);
    transform: rotate(140.4deg);
  }
}

@keyframes loading-40 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}

@keyframes loading-41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(147.6);
    transform: rotate(147.6deg);
  }
}

@keyframes loading-42 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(151.2);
    transform: rotate(151.2deg);
  }
}

@keyframes loading-43 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(154.8);
    transform: rotate(154.8deg);
  }
}

@keyframes loading-44 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(158.4);
    transform: rotate(158.4deg);
  }
}

@keyframes loading-45 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(162);
    transform: rotate(162deg);
  }
}

@keyframes loading-46 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(165.6);
    transform: rotate(165.6deg);
  }
}

@keyframes loading-47 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(169.2);
    transform: rotate(169.2deg);
  }
}

@keyframes loading-48 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(172.8);
    transform: rotate(172.8deg);
  }
}

@keyframes loading-49 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(176.4);
    transform: rotate(176.4deg);
  }
}

@keyframes loading-50 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}

.progress {
  margin-bottom: 1em;
}

.globe {
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .globe {
    width: 100vw;
    overflow-x: hidden;
    background: white;
  }
}

@media only screen and (max-width: 991px) {
  .globe > .row {
    flex-direction: column-reverse;
  }
}

.globe .sidebar {
  padding: 0;
  background: white;
}

.globe .sidebar .top {
  background: #F5F9FD;
  padding: 12rem 3rem 0 4.5rem;
  height: 30vh;
}

@media only screen and (max-width: 991px) {
  .globe .sidebar .top {
    padding: 3rem;
    height: auto;
    text-align: center;
  }
}

.globe .sidebar .clients {
  padding: 3rem 3rem 3rem 4.5rem;
  height: 70vh;
  overflow-y: scroll;
}

@media only screen and (max-width: 991px) {
  .globe .sidebar .clients {
    height: auto;
    min-height: 10rem;
  }
}

.globe .sidebar .clients .client {
  display: none;
}

.globe .sidebar .clients .client.show {
  display: block;
}

.globe .sidebar .clients .client > div,
.globe .sidebar .clients a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  max-width: 15rem;
  overflow: hidden;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 3rem;
  text-indent: -99999px;
}

.globe .sidebar .clients .client > div:hover,
.globe .sidebar .clients a:hover {
  opacity: 0.7;
}

.globe .sidebar h2,
.globe .sidebar header .details .info p,
header .details .info .globe .sidebar p,
.globe .sidebar .share p,
.share .globe .sidebar p,
.globe .sidebar .contact-list-boxed li,
.contact-list-boxed .globe .sidebar li,
.globe .sidebar .purchase-card .price,
.purchase-card .globe .sidebar .price,
.globe .sidebar .cart-item .course-price .price,
.cart-item .course-price .globe .sidebar .price {
  color: #7BA7C5;
  display: none;
}

.globe .sidebar h2.show,
.globe .sidebar header .details .info p.show,
header .details .info .globe .sidebar p.show,
.globe .sidebar .share p.show,
.share .globe .sidebar p.show,
.globe .sidebar .contact-list-boxed li.show,
.contact-list-boxed .globe .sidebar li.show,
.globe .sidebar .purchase-card .show.price,
.purchase-card .globe .sidebar .show.price,
.globe .sidebar .cart-item .course-price .show.price,
.cart-item .course-price .globe .sidebar .show.price {
  display: block;
}

.globe .main {
  padding: 0;
  background-color: black;
}

@media only screen and (max-width: 991px) {
  .globe .main {
    overflow: hidden;
    padding-top: 11rem;
  }
}

.globe .main #earth_div {
  height: 100vh;
}

@media only screen and (max-width: 991px) {
  .globe .main #earth_div {
    height: 50vh;
    width: 100%;
    overflow: hidden;
  }
}

.globe .main #earth_div .cesium-credit-textContainer,
.globe .main #earth_div .cesium-credit-imageContainer {
  display: none;
}

.video-background {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#vidtop-content {
  top: 0;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}

