.pagination {
    justify-content: center;
}

.pagination>li>a,
.pagination>li>span {
    color: black;
    border: none;
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    background: transparent;
    font-family: 'Poppins', sans-serif;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.pagination>li>a:hover,
.pagination>li>span:hover {
    opacity: 1;
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
    color: #FFF;
}

.pagination>li.disabled {
    display: none;
}
