.course {
    header {
        background: $color-primary;
        color: white;
        padding: 2rem;
        font-weight: bold;
        height: 7.5rem;
        min-height: auto;
        justify-content: flex-start;
        align-items: center;

        &:before {
            display: none;
        }

        .ml-auto .button {
            @include breakpoint('md-down') {
                padding: 0;
                display: none;
            }
        }
    }

    section {
        padding: 0;
        background: white;
    }

    aside {
        background: $color-gray;
        position: absolute;
        z-index: 5;
        height: calc(100vh - 7rem);
        padding: 0;
        left: -33%;
        // border-right: 1px solid $color-gray-darker;
        @include transition;

        @include breakpoint('md-down') {
            left: -100%;
            height: 100%;
        }

        &.show {
            left: 0;
        }

        .content {
            height: calc(100% - 7rem);
            overflow-y: auto;
            padding: 2rem;

            h3 {
                color: $color-secondary;
                font-weight: 500;
                font-size: 1.7rem;

                &:not(:first-of-type) {
                    border-top: 1px solid $color-gray-darker;
                    padding: 2rem 2rem 0px;
                    margin: 3rem -2rem 2rem;
                }
            }

            .course-page-list {
                list-style: none;
                padding: 0;

                li.checkbox {
                    margin-bottom: 1rem;

                    input {
                        &:before {
                            top: 3px;
                            border: 1px solid $color-gray-darker;
                            background-color: white;
                            width: 2rem;
                            height: 2rem;
                            display: flex;
                        }

                        &:checked:before {
                            background-color: $color-tertiary;
                        }
                    }

                    a {
                        color: black;
                        font-weight: 500;
                        font-size: 1.4rem;

                        &:hover {
                            color: $color-secondary;
                            text-decoration: none;
                        }
                    }

                    &.active a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    article {
        padding: 2rem;
        height: calc(100vh - 7.5rem);
        z-index: 1;
        @include transition;

        .content {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 20rem);
            padding: 3rem 3rem 0;

            @include breakpoint('md-down') {
                padding: 0;
            }

            .text {
                overflow-y: auto;

                @include breakpoint('md-down') {
                	padding-bottom: 6rem;
                }
            }

            h2 {
                color: $color-tertiary;
                margin-bottom: 2rem;
            }

            img {
                margin: 0rem 2rem 2rem;
                // max-width: 100%;
                // max-width: calc(100% - 4rem);
                object-fit: cover;
            }

            p {
                margin-bottom: 2rem;
            }


            table {
                margin-bottom: 2rem;
                width: 100% !important;

                @include breakpoint('md-down') {
                    display: block;
                    width: 100% !important;
                    height: auto !important;
                }

                tr {
                    vertical-align: top;

                    @include breakpoint('md-down') {
                        display: block;
                        width: 100% !important;
                        height: auto !important;
                    }

                    td {
                        // width: auto !important;
                        width: 10% !important;

                        @include breakpoint('md-down') {
                            display: block;
                            width: 100% !important;
                            height: auto !important;
                        }

                        > img {
                            max-width: 100%;
                            // padding: 0rem 2rem 2rem;
                            margin: 0 2rem 2rem;
                            // margin: 0;
                        }

                        * {
                            font-size: 1.7rem !important;
                        }

                        p {
                            img {
                                margin-bottom: 0;
                            }
                        }

                        li {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }

            .course-length {
                background: $color-gray;
                margin-top: 3rem;
                padding: 2rem 3rem;
                display: inline-block;

                @include breakpoint('md-down') {
                    width: 100%;
                }

                dl {
                    // display: flex;
                    margin-bottom: 0;

                    dt {
                        font-weight: 400;
                        display: inline-block;
                        min-width: 22rem;

                        @include breakpoint('md-down') {
                            min-width: 20rem;
                        }
                    }

                    dd {
                        font-weight: 600;
                        display: inline-block;
                        color: $color-tertiary;
                    }
                }
            }

            // h3 {
            //     color: $color-tertiary;
            // }
        }

        footer {
            justify-content: flex-end;
            padding: 0;
            border: 0;
            position: fixed;
            width: 100vw;
            height: 11rem;
            left: 50%;
            right: 50%;
            bottom: 0;
            margin-left: -50vw;
            margin-right: -50vw;

            @include breakpoint('md-down') {
                // position: absolute;
                form {
                    position: absolute;
                }
            }

            .page-count {
                position: absolute;
                left: 0;
                width: 100vw;
                margin-top: 3rem;
                text-align: center;
            }

            .button {
                @include breakpoint('md-down') {
                    padding: 0 1rem;
                    min-width: 0;
                }
            }
        }
    }

    .slant {
        z-index: -1;

        @include breakpoint('md-down') {
            bottom: 0;
            position: fixed;
        }
    }

    .exam-container {
        padding: 0 0 3rem;

        // input[type=radio][name=answer] {
        //     margin-right: 10px;
        // }
    }

    .question-title {
        color: $color-tertiary;
        font-weight: 600;
    }

    .question-subtitle {
        font-size: 1.6rem;
        color: $color-gray-darker;
        margin-bottom: 3rem;
    }

    .question-content {
        margin-bottom: 3rem;
    }

    .next-question {
        &:disabled {
            opacity: 0.7;
            cursor: auto;
        }
    }

    .question-count {
        font-weight: bold;
        bottom: 0;
        height: 5rem;
        line-height: 5rem;
        position: fixed;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        pointer-events: none;
    }

    .timer {
        font-weight: bold;
        height: 11rem;
        line-height: 5rem;
        position: fixed;
        bottom: 0;
        left: 2rem;
        display: flex;
        align-items: center;

        span {
            display: inline-block;
            margin-left: 1rem;
            color: $color-tertiary;
        }
    }

    .pull-right {
        float: right;
    }

    .return-link {
        color: $color-gray-darker;

        &:hover {
            color: $color-gray-darker;
            opacity: 0.5;
        }
    }

    .question-answers {
        list-style-type: upper-alpha;
        list-style-image: none;
        max-height: 35rem;
        overflow-y: scroll;

        li {
            font-weight: 600;
            position: relative;

            &:hover {
                color: $color-tertiary;
                background: $color-gray;

                &:before {
                    content: '';
                    display: block;
                    width: 4rem;
                    height: 100%;
                    background: $color-gray;
                    position: absolute;
                    margin-left: -4rem;
                    z-index: -1;
                }

                label {
                    color: $color-gray-darker;
                }
            }

            label {
                margin-bottom: 0;
                margin-left: -4rem;
                padding: 2rem 5rem;
                font-weight: 400;
                overflow: hidden;

                input {
                    top: 2rem;
                    right: 0;

                    &:before {
                        border-color: $color-secondary;
                        left: auto;
                        right: 2rem;
                        top: 0;
                    }

                    &:focus:before,
                    &:hover:before,
                    &:checked:before {
                        background-color: $color-secondary;
                    }

                    &:focus:before,
                    &:hover:before {
                        border-color: $color-secondary-dark;
                    }

                    &:checked:after {
                        content: '';
                        display: block;
                        background-color: $color-gray;
                        width: 150vw;
                        height: 150vw;
                        left: -100vw;
                        top: -5rem;
                        z-index: -1;
                        position: absolute;
                        visibility: visible;
                    }
                }
            }
        }
    }

    .exam-answers {
        max-height: 35rem;
        overflow-y: scroll;
    }
}

.courses-title {
    display: flex;
    align-items: center;

    @include breakpoint('md-down') {
        flex-direction: column;
    }

    h1 {
        display: inline-block;
    }
}