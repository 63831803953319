.blog-post {
    .content {
        h2 {
            margin-bottom: 3rem;
            color: $color-tertiary;
        }

        h3 {
            margin-bottom: 1.5rem;
            color: $color-tertiary;
        }

        p + h2,
        p + h3 {
            margin-top: 3rem;
        }
    }
}


.img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    overflow: hidden;
}
