.awards {
    margin-top: -45rem;

    @include breakpoint('md-down') {
        margin-top: -55rem;
        overflow-x: hidden;
    }

    .card {
        z-index: 3;
        color: black;
        height: auto;


        img {
            max-width: 25rem;
            margin: 0 auto 2rem;
        }

        h3 {
            color: $color-tertiary;
            margin-bottom: 2rem;
        }

        hr {
            margin: 3rem -5rem;
        }

        .author {
            display: flex;
            margin-top: 3rem;

            .img-round {
                width: 9rem;
                height: 9rem;
            }

            .content {
                flex-direction: column;
                padding: 0;
                padding-left: 2rem;
                align-items: flex-start;
                justify-content: center;

                h3,
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
