.social-icons {
    list-style: none;
    padding: 0;
    display: flex;

    @include breakpoint('md-down') {
        justify-content: center;
    }
        
    li {
        a {
            display: flex;
            font-size: 2rem;
            width: 5rem;
            height: 5rem;
            align-items: center;
            justify-content: center;
            border: 3px solid $color-secondary;
            margin-right: 3rem;
            color: black;
            transition: 0.5s ease;

            @include breakpoint('md-down') {
                footer & {
                    color: $color-secondary;
                    background: $color-gray;
                    border-color: $color-gray;
                }
            }

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
                background: $color-secondary;
                color: white;

                .fa[class*=via-]:before {
                    background-color: white;
                }
            }
        }
    }
}
