.p-6 {
    padding: 5rem !important;
}

.pt-6,
.py-6 {
    padding-top: 5rem !important;
}

.pr-6,
.px-6 {
    padding-right: 5rem !important;
}

.pb-6,
.py-6 {
    padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
    padding-left: 5rem !important;
}

.p-7 {
    padding: 10rem !important;
}

.pt-7,
.py-7 {
    padding-top: 10rem !important;
}

.pr-7,
.px-7 {
    padding-right: 10rem !important;
}

.pb-7,
.py-7 {
    padding-bottom: 10rem !important;
}

.pl-7,
.px-7 {
    padding-left: 10rem !important;
}


.m-6 {
    margin: 5rem !important;
}

.mt-6,
.my-6 {
    margin-top: 5rem !important;
}

.mr-6,
.mx-6 {
    margin-right: 5rem !important;
}

.mb-6,
.my-6 {
    margin-bottom: 5rem !important;
}

.ml-6,
.mx-6 {
    margin-left: 5rem !important;
}

.m-n6 {
    margin: -5rem !important;
}

.mt-n6,
.my-n6 {
    margin-top: -5rem !important;
}

.mr-n6,
.mx-n6 {
    margin-right: -5rem !important;
}

.mb-n6,
.my-n6 {
    margin-bottom: -5rem !important;
}

.ml-n6,
.mx-n6 {
    margin-left: -5rem !important;
}

.m-7 {
    margin: 10rem !important;
}

.mt-7,
.my-7 {
    margin-top: 10rem !important;
}

.mr-7,
.mx-7 {
    margin-right: 10rem !important;
}

.mb-7,
.my-7 {
    margin-bottom: 10rem !important;
}

.ml-7,
.mx-7 {
    margin-left: 10rem !important;
}

.m-n7 {
    margin: -10rem !important;
}

.mt-n7,
.my-n7 {
    margin-top: -10rem !important;
}

.mr-n7,
.mx-n7 {
    margin-right: -10rem !important;
}

.mb-n7,
.my-n7 {
    margin-bottom: -10rem !important;
}

.ml-n7,
.mx-n7 {
    margin-left: -10rem !important;
}

@media (min-width: 576px) {
    .m-sm-6 {
        margin: 5rem !important;
    }

    .mt-sm-6,
    .my-sm-6 {
        margin-top: 5rem !important;
    }

    .mr-sm-6,
    .mx-sm-6 {
        margin-right: 5rem !important;
    }

    .mb-sm-6,
    .my-sm-6 {
        margin-bottom: 5rem !important;
    }

    .ml-sm-6,
    .mx-sm-6 {
        margin-left: 5rem !important;
    }

    .m-sm-7 {
        margin: 10rem !important;
    }

    .mt-sm-7,
    .my-sm-7 {
        margin-top: 10rem !important;
    }

    .mr-sm-7,
    .mx-sm-7 {
        margin-right: 10rem !important;
    }

    .mb-sm-7,
    .my-sm-7 {
        margin-bottom: 10rem !important;
    }

    .ml-sm-7,
    .mx-sm-7 {
        margin-left: 10rem !important;
    }

    .p-sm-6 {
        padding: 5rem !important;
    }

    .pt-sm-6,
    .py-sm-6 {
        padding-top: 5rem !important;
    }

    .pr-sm-6,
    .px-sm-6 {
        padding-right: 5rem !important;
    }

    .pb-sm-6,
    .py-sm-6 {
        padding-bottom: 5rem !important;
    }

    .pl-sm-6,
    .px-sm-6 {
        padding-left: 5rem !important;
    }

    .p-sm-7 {
        padding: 10rem !important;
    }

    .pt-sm-7,
    .py-sm-7 {
        padding-top: 10rem !important;
    }

    .pr-sm-7,
    .px-sm-7 {
        padding-right: 10rem !important;
    }

    .pb-sm-7,
    .py-sm-7 {
        padding-bottom: 10rem !important;
    }

    .pl-sm-7,
    .px-sm-7 {
        padding-left: 10rem !important;
    }

    .m-sm-n6 {
        margin: -5rem !important;
    }

    .mt-sm-n6,
    .my-sm-n6 {
        margin-top: -5rem !important;
    }

    .mr-sm-n6,
    .mx-sm-n6 {
        margin-right: -5rem !important;
    }

    .mb-sm-n6,
    .my-sm-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-sm-n6,
    .mx-sm-n6 {
        margin-left: -5rem !important;
    }

    .m-sm-n7 {
        margin: -10rem !important;
    }

    .mt-sm-n7,
    .my-sm-n7 {
        margin-top: -10rem !important;
    }

    .mr-sm-n7,
    .mx-sm-n7 {
        margin-right: -10rem !important;
    }

    .mb-sm-n7,
    .my-sm-n7 {
        margin-bottom: -10rem !important;
    }

    .ml-sm-n7,
    .mx-sm-n7 {
        margin-left: -10rem !important;
    }
}

@media (min-width: 768px) {
    .m-md-6 {
        margin: 5rem !important;
    }

    .mt-md-6,
    .my-md-6 {
        margin-top: 5rem !important;
    }

    .mr-md-6,
    .mx-md-6 {
        margin-right: 5rem !important;
    }

    .mb-md-6,
    .my-md-6 {
        margin-bottom: 5rem !important;
    }

    .ml-md-6,
    .mx-md-6 {
        margin-left: 5rem !important;
    }

    .m-md-7 {
        margin: 10rem !important;
    }

    .mt-md-7,
    .my-md-7 {
        margin-top: 10rem !important;
    }

    .mr-md-7,
    .mx-md-7 {
        margin-right: 10rem !important;
    }

    .mb-md-7,
    .my-md-7 {
        margin-bottom: 10rem !important;
    }

    .ml-md-7,
    .mx-md-7 {
        margin-left: 10rem !important;
    }

    .p-md-6 {
        padding: 5rem !important;
    }

    .pt-md-6,
    .py-md-6 {
        padding-top: 5rem !important;
    }

    .pr-md-6,
    .px-md-6 {
        padding-right: 5rem !important;
    }

    .pb-md-6,
    .py-md-6 {
        padding-bottom: 5rem !important;
    }

    .pl-md-6,
    .px-md-6 {
        padding-left: 5rem !important;
    }

    .p-md-7 {
        padding: 10rem !important;
    }

    .pt-md-7,
    .py-md-7 {
        padding-top: 10rem !important;
    }

    .pr-md-7,
    .px-md-7 {
        padding-right: 10rem !important;
    }

    .pb-md-7,
    .py-md-7 {
        padding-bottom: 10rem !important;
    }

    .pl-md-7,
    .px-md-7 {
        padding-left: 10rem !important;
    }

    .m-md-n6 {
        margin: -5rem !important;
    }

    .mt-md-n6,
    .my-md-n6 {
        margin-top: -5rem !important;
    }

    .mr-md-n6,
    .mx-md-n6 {
        margin-right: -5rem !important;
    }

    .mb-md-n6,
    .my-md-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-md-n6,
    .mx-md-n6 {
        margin-left: -5rem !important;
    }

    .m-md-n7 {
        margin: -10rem !important;
    }

    .mt-md-n7,
    .my-md-n7 {
        margin-top: -10rem !important;
    }

    .mr-md-n7,
    .mx-md-n7 {
        margin-right: -10rem !important;
    }

    .mb-md-n7,
    .my-md-n7 {
        margin-bottom: -10rem !important;
    }

    .ml-md-n7,
    .mx-md-n7 {
        margin-left: -10rem !important;
    }
}

@media (min-width: 992px) {
    .m-lg-6 {
        margin: 5rem !important;
    }

    .mt-lg-6,
    .my-lg-6 {
        margin-top: 5rem !important;
    }

    .mr-lg-6,
    .mx-lg-6 {
        margin-right: 5rem !important;
    }

    .mb-lg-6,
    .my-lg-6 {
        margin-bottom: 5rem !important;
    }

    .ml-lg-6,
    .mx-lg-6 {
        margin-left: 5rem !important;
    }

    .m-lg-7 {
        margin: 10rem !important;
    }

    .mt-lg-7,
    .my-lg-7 {
        margin-top: 10rem !important;
    }

    .mr-lg-7,
    .mx-lg-7 {
        margin-right: 10rem !important;
    }

    .mb-lg-7,
    .my-lg-7 {
        margin-bottom: 10rem !important;
    }

    .ml-lg-7,
    .mx-lg-7 {
        margin-left: 10rem !important;
    }

    .p-lg-6 {
        padding: 5rem !important;
    }

    .pt-lg-6,
    .py-lg-6 {
        padding-top: 5rem !important;
    }

    .pr-lg-6,
    .px-lg-6 {
        padding-right: 5rem !important;
    }

    .pb-lg-6,
    .py-lg-6 {
        padding-bottom: 5rem !important;
    }

    .pl-lg-6,
    .px-lg-6 {
        padding-left: 5rem !important;
    }

    .p-lg-7 {
        padding: 10rem !important;
    }

    .pt-lg-7,
    .py-lg-7 {
        padding-top: 10rem !important;
    }

    .pr-lg-7,
    .px-lg-7 {
        padding-right: 10rem !important;
    }

    .pb-lg-7,
    .py-lg-7 {
        padding-bottom: 10rem !important;
    }

    .pl-lg-7,
    .px-lg-7 {
        padding-left: 10rem !important;
    }

    .m-lg-n6 {
        margin: -5rem !important;
    }

    .mt-lg-n6,
    .my-lg-n6 {
        margin-top: -5rem !important;
    }

    .mr-lg-n6,
    .mx-lg-n6 {
        margin-right: -5rem !important;
    }

    .mb-lg-n6,
    .my-lg-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-lg-n6,
    .mx-lg-n6 {
        margin-left: -5rem !important;
    }

    .m-lg-n7 {
        margin: -10rem !important;
    }

    .mt-lg-n7,
    .my-lg-n7 {
        margin-top: -10rem !important;
    }

    .mr-lg-n7,
    .mx-lg-n7 {
        margin-right: -10rem !important;
    }

    .mb-lg-n7,
    .my-lg-n7 {
        margin-bottom: -10rem !important;
    }

    .ml-lg-n7,
    .mx-lg-n7 {
        margin-left: -10rem !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-6 {
        margin: 5rem !important;
    }

    .mt-xl-6,
    .my-xl-6 {
        margin-top: 5rem !important;
    }

    .mr-xl-6,
    .mx-xl-6 {
        margin-right: 5rem !important;
    }

    .mb-xl-6,
    .my-xl-6 {
        margin-bottom: 5rem !important;
    }

    .ml-xl-6,
    .mx-xl-6 {
        margin-left: 5rem !important;
    }

    .m-xl-7 {
        margin: 10rem !important;
    }

    .mt-xl-7,
    .my-xl-7 {
        margin-top: 10rem !important;
    }

    .mr-xl-7,
    .mx-xl-7 {
        margin-right: 10rem !important;
    }

    .mb-xl-7,
    .my-xl-7 {
        margin-bottom: 10rem !important;
    }

    .ml-xl-7,
    .mx-xl-7 {
        margin-left: 10rem !important;
    }

    .p-xl-6 {
        padding: 5rem !important;
    }

    .pt-xl-6,
    .py-xl-6 {
        padding-top: 5rem !important;
    }

    .pr-xl-6,
    .px-xl-6 {
        padding-right: 5rem !important;
    }

    .pb-xl-6,
    .py-xl-6 {
        padding-bottom: 5rem !important;
    }

    .pl-xl-6,
    .px-xl-6 {
        padding-left: 5rem !important;
    }

    .p-xl-7 {
        padding: 10rem !important;
    }

    .pt-xl-7,
    .py-xl-7 {
        padding-top: 10rem !important;
    }

    .pr-xl-7,
    .px-xl-7 {
        padding-right: 10rem !important;
    }

    .pb-xl-7,
    .py-xl-7 {
        padding-bottom: 10rem !important;
    }

    .pl-xl-7,
    .px-xl-7 {
        padding-left: 10rem !important;
    }

    .m-xl-n6 {
        margin: -5rem !important;
    }

    .mt-xl-n6,
    .my-xl-n6 {
        margin-top: -5rem !important;
    }

    .mr-xl-n6,
    .mx-xl-n6 {
        margin-right: -5rem !important;
    }

    .mb-xl-n6,
    .my-xl-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-xl-n6,
    .mx-xl-n6 {
        margin-left: -5rem !important;
    }

    .m-xl-n7 {
        margin: -10rem !important;
    }

    .mt-xl-n7,
    .my-xl-n7 {
        margin-top: -10rem !important;
    }

    .mr-xl-n7,
    .mx-xl-n7 {
        margin-right: -10rem !important;
    }

    .mb-xl-n7,
    .my-xl-n7 {
        margin-bottom: -10rem !important;
    }

    .ml-xl-n7,
    .mx-xl-n7 {
        margin-left: -10rem !important;
    }
}
