nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    height: 22.0rem;
    width: 100vw;
    @include transition;

    @include breakpoint('md-down') {
        height: 11.0rem;

        .slant {
            height: 11.0rem;
        }

        .slant-top-left:before {
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        .slant-top-right:after {
            right: 0;
            width: 66%;
            background-image: none !important;
        }
    }

	&.scroll {
        @include breakpoint('lg-up') {
    	    height: 11.0rem;
    	    // background: white;

    		.slant {
    			height: 11.0rem;
    		}

    		.slant-top-left:before {
    			-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    			        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    			height: 11.0rem;
    		}

    		.slant-top-right:after {
    			height: 0;
    		}

    		.logo p.small {
    		    opacity: 0;
    		    // display: none;
    		    position: absolute;
    		}
        }
	}

    .slant-top-right:after {
    	background-image: url('../images/globe.png');
	    background-size: 300px;
	    background-repeat: no-repeat;
	    background-position-x: 75%;
	    background-position-y: center;
    }

	.logo {
	    width: 29.5rem;
	    padding: 0 3rem;
	    position: relative;
	    z-index: 5;

        @include breakpoint('md-down') {
            width: 20rem;
            padding-left: 2rem;
        }

		img {
		    max-width: 100%;
		}

		p.small {
		    font-size: 1.3rem;
		    line-height: 1.5rem;
		    margin: 1rem 0 0;
		    font-family: 'Poppins', sans-serif;
		    opacity: 1;
		    position: relative;
		    @include transition;
		}
	}

	.buttons {
	    padding: 0 3rem;
	    position: relative;
	    z-index: 5;

        @include breakpoint('md-down') {
            padding-right: 2rem;
            @include transition;

            body.overflow & {
                padding-right: 1rem;
            }
        }

        .open-menu {
            @include breakpoint('md-down') {
                min-width: 4rem;
                width: 4rem;
                height: 4rem;
                padding: 0;
            }

            > i {
            	@include transition;
            }
        }

	    .via-bars,
	    .via-cross {
	    	height: 1.2em;
	    	overflow: visible;

            &:before {
                @include breakpoint('md-down') {
                    background-color: white !important;
                }
            }
	    }
	}
}


ul.big-menu {
    list-style: none;
    padding: 0;
    font-size: 1.9rem;
    line-height: 2.4rem;

	#mobile-menu & {
		&.open {
			left: -90vw;
		}

		&:after {
			height: 100vh;
			width: 85vw;
			display: block;
			content: '';
			position: absolute;
			z-index: 0;
			left: 100%;
			top: 0;
			background: $color-primary;
		}
	}

	li {
		#menu & {
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		#mobile-menu & {
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a,
        span {
		    font-family: 'Poppins', sans-serif;
		    font-weight: 600;
		    color: $color-tertiary;

		    &:hover,
		    &:focus {
				color: $color-tertiary-dark;
		    }

		    #mobile-menu & {

		    	&:not(.button) {
					color: white;
					display: block;
		    	}

		    	&.button {
		    		margin-top: 2rem;
		    	}

				&.sub-menu {
					display: flex;
					align-items: center;

					&.open {
						&:after {
							margin-right: -2rem;
						}
					}

					&:after {
						width: 1.2em;
						height: 1.2em;
						display: inline-block;
						content: '';
						margin-left: auto;
						margin-right: 0;
						mask-size: contain;
						mask: url('../images/icons/chevron-right.svg') no-repeat 50% 50%;
						background: $color-secondary;
						@include transition;
					}
				}
		    }
		}
	}
}

ul.small-menu {
    list-style: none;
    padding: 0;
    font-size: 1.4rem;

    #mobile-menu & {
		position: absolute;
		left: 100%;
		top: 0;
		padding: 3rem 2rem;
		background: $color-primary;
		height: 100vh;
		width: 85vw;
		display: none;
		overflow-y: scroll;
		z-index: 1;
		@include transition;

		&.show {
			display: block;
		}
    }

	li {
        #menu &,
        #mobile-menu & {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

		a:not(.button) {
			display: inline-block;
		    color: black;
		    font-weight: 500;

            #menu &,
            #mobile-menu & {
                color: white;

                &:hover,
                &:focus {
                    color: $color-gray-darker;
                }
            }
		}
	}
}


#menu {
    position: fixed;
    z-index: 555;
    height: 100vh;
    width: 100vw;
    top: -100%;
    left: 0;
    padding: 6rem;
    background-color: $color-primary-dark;
    background-image: url('../images/globe.png');
    background-size: 35%;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 180%;
    opacity: 0;
    @include transition;

    @include breakpoint('md-down') {
        display: none;
    }

    &.show {
        top: 0;
        opacity: 1;
    }

    hr {
        border-color: $color-primary;
    }

    .close-menu {
        position: absolute;
        bottom: 0;
        width: 120px;
        left: calc(50% - 60px);
    }
}

#mobile-menu {
    position: fixed;
    z-index: 555;
    height: 100vh;
    width: 85vw;
    top: 0;
    left: 100%;
    padding: 3rem 2rem;
    background-color: $color-primary-dark;
    border-left: 1px solid rgba($color-tertiary, 0.2);
    @include transition;

    @include breakpoint('lg-up') {
        display: none;
    }

    &.show {
        left: 15vw;

		&.show-sub-menu {
			left: -70vw;
		}
    }

    hr {
        border-color: $color-primary;
    }

    .close-menu {
        position: absolute;
        bottom: 0;
        width: 120px;
        left: calc(50% - 60px);
    }
}
