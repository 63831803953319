.card {
    height: 100%;
    background: white;
    border: 0;
    border-radius: 0;

    .img {
        height: 150px;
        position: relative;

        // &:before {
        //     display: block;
        //     content: "";
        //     height: 100%;
        //     width: 100%;
        //     position: absolute;
        //     background: $color-primary;
        //     -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 2rem);
        //             clip-path: polygon(0 0, 100% 0, 100% 0, 0 2rem);
        // }

        &:after {
            display: block;
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            background: white;
            -webkit-clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
                    clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
        }
    }

    .content {
        display: flex;
        align-items: center;
        padding: 2rem;
        color: black;

        img {
            margin-right: 2rem;
            border-radius: 50%;
            max-height: 50px;
        }
    }
}


.course-card {
    display: block;
    background: white;
    height: 26rem;

    @include breakpoint('md-down') {
        height: auto;
        min-height: 20rem;
    }

    > .row {
        height: 100%;

        @include breakpoint('md-down') {
            min-height: 20rem;
        }
    }

    .img {
        height: 100%;

        @include breakpoint('md-down') {
           min-height: 15rem;
        }
    }


    .content {
        padding: 1.5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @include breakpoint('md-down') {
            //padding: 2rem 3rem 1.5rem;
        }

        h2 {
            @include breakpoint('md-down') {
                font-size: 1.9rem;
                line-height: 2.7rem;
                margin-bottom: 1rem;
            }
        }

        .details {
            margin-top: auto;

            .info {
                display: inline-block;

                h5 {
                    color: $color-tertiary;
                }

                p {
                    font-family: 'Poppins', sans-serif;
                    font-size: 1.7rem;
                    color: black;
                }

                & + .info {
                    margin-left: 5rem;
                }
            }
        }
    }
}

.purchase-card {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include breakpoint('md-down') {
        padding-top: 3rem;
    }

    img {
        max-width: 8rem;
        margin-bottom: 3rem;
    }

    .users {
        @extend h3;

    }

    .price {
        @extend h2;
        color: $color-secondary;
        margin-bottom: 3rem;
    }

    .button {
        @include breakpoint('md-down') {
            padding: 0 1rem;
        }
    }
}

.books {
    .card {
        h2 {
            @include breakpoint('md-down') {
                font-size: 1.9rem;
                line-height: 2.7rem;
            }
        }
    }
}
