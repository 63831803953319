.button-box {
    padding: 3rem 3rem 0;
    color: white;
    min-height: 27rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    &:after {
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: $color-tertiary;
        opacity: 0.8;
    }

    h2,
    a,
    p {
        z-index: 5;
    }

    h2 {
        color: white;
        margin-bottom: 2rem;
    }

    .button {
        margin-top: auto;
    }
}


.bkgd-image-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-primary-dark;
        opacity: 0.5;
    }
}

.no-overlay:before,
.no-overlay:after {
	display: none;
}

.raise {
    position: relative;
    z-index: 5;
}


.book {
    a:hover {
        img:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: white;
            opacity: 0.5;
        }
    }
    
    img {
        max-height: 250px;
        margin: 0 auto;
    }
}

.quote {
    .author {
        display: flex;
        margin-top: 3rem;

        .img {
            width: 10rem;
            height: 10rem;
            border-radius: 50%;
            margin-right: 3rem;
        }
    }
}

.share {
    padding: 5rem 0;
    p {
        @extend h2;
        display: inline-block;
        color: $color-tertiary;
        margin-right: 5rem;
    }

    .social-icons {
        display: inline-flex;
    }
}

.course-list {
    position: relative;
    margin-top: -350px;
    z-index: 5;

    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $color-gray;
        -webkit-clip-path: polygon(0 351px, 100% 351px, 100% 100%, 0% 100%);
        clip-path: polygon(0 351px, 100% 351px, 100% 100%, 0% 100%);
    }
}

.exam-info {
    background: $color-gray;
    padding: 3rem;
    margin-top: 3rem;

    .info { 
        .label {
            display: inline-block;
            width: 25rem;
        }

        .value {
            @extend h3;
            display: inline-block;
            color: $color-tertiary;
        }
    }
}
