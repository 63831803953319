.modal {

    .modal-body {
        background: $color-gray;
        border-radius: 0;
        padding: 3rem;
        display: flex;
        flex-direction: column;

        .button {
            width: 100%;
        }
    }

}
