.white {
	color: white !important;
}

.black {
	color: black !important;
}

.primary {
	color: $color-primary !important;

	&-light {
		color: $color-primary-light !important;
	}

	&-dark {
		color: $color-primary-dark !important;
	}
}

.secondary {
	color: $color-secondary !important;

	&-light {
		color: $color-secondary-light !important;
	}

	&-dark {
		color: $color-secondary-dark !important;
	}
}

.tertiary {
	color: $color-tertiary !important;

	&-light {
		color: $color-tertiary-light !important;
	}

	&-dark {
		color: $color-tertiary-dark !important;
	}
}

.gray {
	color: $color-gray !important;

	&-light {
		color: $color-gray-light !important;
	}

	&-dark {
		color: $color-gray-dark !important;
	}

	&-darker {
		color: $color-gray-darker !important;
	}
}

b, strong, .bold {
	font-weight: 700;
}

.flex {
	display: flex;

	&--center {
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include ie11() {
			align-items: stretch;
		}

		&-left {
			justify-content: center;
			flex-direction: column;
			align-items: flex-start;

			@include ie11() {
				align-items: stretch;
			}
		}

		&-right {
			justify-content: center;
			flex-direction: column;
			align-items: flex-end;
		}
	}

	&--top {
		justify-content: flex-start !important;
	}

	@include ie11() {
		&-ie {
			justify-content: flex-start !important;
		}
	}
}

.img {
	&-ratio16-9 {
		padding-top: 56.25% !important;
	}

	&-ratio4-3 {
		padding-top: 75% !important;
	}

	&-ratio3-2 {
		padding-top: 66.66% !important;
	}

    &-ratio8-5 {
        padding-top: 62.5% !important;
    }

    &-tall {
        padding-top: 110% !important;
    }

    &-square {
        padding-top: 100% !important;
    }

    &-circle {
        border-radius: 50%;
        padding-top: 100% !important;
    }

    &-round {
        border-radius: 50%;
    }

    &-rounded {
        border-radius: 8px;
    }

    &-br {
        position: absolute;
        bottom: -30%;
        right: -30px;
    }


	// &:hover:after {
	//     display: block;
	//     content: '';
	//     background: white;
	//     position: absolute;
	//     top: 0;
	//     left: 0;
	//     width: 100%;
	//     height: 100%;
	//     opacity: 0.5;
	//     pointer-events: none;
	// }
}

.desktop-only {
	@include breakpoint('md-down') {
		display: none !important;
	}
}

.mobile-only {
	@include breakpoint('lg-up') {
		display: none !important;
	}
}


.video {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

#showreel button {
    position: absolute;
    bottom: -100px;
    left: calc(50% - 60px);
}

#twitter-widget-0 {
    border: 3px solid $color-gray !important;
}

ul {
	list-style-image: url('../images/icons/arrow-bullet.svg');
}
