.cart-item {
    padding: 3rem;
    margin-top: 3rem;
    background-color: $color-gray;

    @include breakpoint('md-down') {
        padding: 2rem;
    }

    h2 {
        margin-bottom: 3rem;
    }

    h3 {
        color: $color-tertiary;
    }

    .buttons {
        @include breakpoint('md-down') {
            flex-direction: column-reverse;
        }
    }

    .course-price {
        text-align: right;

        @include breakpoint('md-down') {
            text-align: left;
        }

        .price {
            @extend h2;

            span {
                color: $color-tertiary;

                @include breakpoint('lg-up') {
                    display: none;
                }
            }
        }
    }

    .quantity {
        h5 {

        }

        form {
            display: inline-block;

            @include breakpoint('md-down') {
                display: inline-flex;
            }

            label {
                background: white;
                width: auto;
                padding-left: 2rem;

                @include breakpoint('md-down') {
                    width: 60%;
                }

                input {
                    @extend .form-control;
                    margin: 0;
                    border-color: transparent;

                    @include breakpoint('md-down') {
                        width: calc(100% - 50px);
                    }
                }
            }

            button {
                margin-left: 1rem;

                @include breakpoint('md-down') {
                    padding: 0 1rem;
                    width: calc(40% - 1.99rem);
                    margin-left: auto;
                    min-width: 0;
                }
            }
        }
    }
}

.cart-total {
    position: sticky;
    top: 11rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
    margin-top: 3rem;
    background-color: $color-gray;

    @include breakpoint('md-down') {
        padding: 2rem;
    }

    h3 {
        color: $color-tertiary;
        margin-bottom: 2rem;

        .price {
            color: black;
            margin-left: 2rem;
        }
    }

    .button {
        margin-top: 1rem;
    }

    .voucher-button {
        margin-top: 0; 
        margin-left: -1.5rem; 
        min-width: 0; 
        width: calc(100% + 1.5rem);
        padding: 0 1rem;
    }
}

.cart-empty {
    img {
        background-color: $color-gray;
        border-radius: 50%;
        width: 15rem;
        margin-bottom: 5rem;
        padding: 2rem;
    }
}

.cart-info {
    padding: 3rem;
    margin-bottom: 0.25rem;
    background-color: $color-gray;

    @include breakpoint('md-down') {
        padding: 2rem;
    }

    &:first-of-type {
        margin-top: 3rem;
    }

    h3 {
        // margin-bottom: 3rem;
    }

    h4 {
        color: $color-gray-darker;
    }

    p + h4 {
        margin-top: 2rem;
    }

    .form-control.StripeElement {
        padding-top: 2rem;
    }

    .edit-address,
    .address-text {
        &.hide {
            display: none;
        }
    }

    .address-form {
        display: none;

        &.show {
            display: block;
        }
    }
}

.order-item {
    margin-top: 3rem;
    background-color: white;

    .content {
        padding: 3rem;

        h2 {
            margin-bottom: 3rem;
        }

        h3 {
            color: $color-tertiary;
            display: inline-block;

            &:not(:first-of-type) {
                margin-left: 3rem;
            }

            .price {
                color: black;
                margin-left: 1rem;
            }
        }
    }

    .img {
        height: 100%;
    }
}

