$default-size: 5rem;

@mixin size($width, $height) {
    height: $height;
    width: $width;
}

@mixin draw-progress($progress) {
    .pie {
        .half-circle {
            border-color: $color-secondary;
        }

        .left-side {
            transform: rotate($progress * 3.6deg);
        }

        @if $progress <=50 {
            .right-side {
                display: none;
            }
        }

        @else {
            clip: rect(auto, auto, auto, auto);

            .right-side {
                transform: rotate(180deg);
            }
        }
    }
}

.pie-wrapper {
    width: $default-size;
    height: $default-size;
    position: relative;

    .pie {
        width: 100%;
        height: 100%;
        clip: rect(0, $default-size, $default-size, $default-size / 2);
        left: 0;
        position: absolute;
        top: 0;

        .half-circle {
            width: 100%;
            height: 100%;
            border: 3px solid $color-secondary;
            border-radius: 50%;
            clip: rect(0, $default-size / 2, $default-size, 0);
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    .label {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;        
        border-radius: 50%;
        color: $color-primary;
        cursor: default;
        font-size: 1.4rem;
        position: absolute;
    }

    .shadow {
        width: 100%;
        height: 100%;
        border: $default-size / 10 solid $color-gray-dark;
        border-radius: 50%;
        box-shadow: none !important;
    }

    @for $i from 1 through 100 { 
        &.progress-#{$i} {
            .pie {
                .left-side {
                    transform: rotate($i * 3.6deg);
                }

                @if $i <= 50 {
                    .right-side {
                        display: none;
                    }
                }

                @else {
                    clip: rect(auto, auto, auto, auto);

                    .right-side {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}