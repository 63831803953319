.globe {
    padding: 0;

    @include breakpoint('md-down') {
        width: 100vw;
        overflow-x: hidden;
        background: white;
    }

    >.row {
        @include breakpoint('md-down') {
            flex-direction: column-reverse;
        }
    }

    .sidebar {
        padding: 0;
        background: white;

        .top {
            background: $color-gray;
            padding: 12rem 3rem 0 4.5rem;
            height: 30vh;

            @include breakpoint('md-down') {
            	padding: 3rem;
            	height: auto;
            	text-align: center;
            }
        }

        .clients {
            padding: 3rem 3rem 3rem 4.5rem;
            height: 70vh;
            overflow-y: scroll;

            @include breakpoint('md-down') {
                height: auto;
                min-height: 10rem;
            }

            .client {
                display: none;

                &.show {
                    display: block;
                }
            }

            .client > div,
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 6rem;
                max-width: 15rem;
                overflow: hidden;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin: 0 auto 3rem;
                text-indent: -99999px;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        h2 {
            color: $color-tertiary;
            display: none;

            &.show {
                display: block;
            }
        }
    }

    .main {
        padding: 0;
        background-color: black;

        @include breakpoint('md-down') {
            overflow: hidden;
            padding-top: 11rem;
        }

        #earth_div {
            height: 100vh;

            @include breakpoint('md-down') {
                height: 50vh;
                width: 100%;
                overflow: hidden;
            }

            .cesium-credit-textContainer,
            .cesium-credit-imageContainer {
                display: none;
            }
        }
    }
}
