.case-study {
    height: 200px;
    background: white;
    display: block;

	.img {
	    height: 120px;
	    position: relative;

		&:before {
		    display: block;
		    content: "";
		    height: 100%;
		    width: 100%;
		    position: absolute;
		    background: $color-primary;
		    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 2rem);
		            clip-path: polygon(0 0, 100% 0, 100% 0, 0 2rem);
		}

		&:after {
		    display: block;
		    content: "";
		    height: 100%;
		    width: 100%;
		    bottom: -1px;
		    position: absolute;
		    background: white;
		    -webkit-clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
		            clip-path: polygon(0 100%, 100% calc(100% - 2rem), 100% 100%, 0 100%);
		}
	}

	.content {
	    display: flex;
	    align-items: center;
	    height: 80px;
	    padding: 0 2rem;

		img {
		    margin-right: 2rem;
		    border-radius: 50%;
		    max-height: 50px;
		}
	}
}
