html {
    font-size: 16px;
    font-size: 62.5%;
    font-family: 'Work Sans', sans-serif;
    background: $color-tertiary;

    &.overflow {
        overflow: hidden;
    }
}

body {
    font-size: 1.7rem;
    font-family: 'Work Sans', sans-serif;
    @include transition;

    &.overflow {
        overflow: hidden;
    }

    @include breakpoint('md-down') {
        font-size: 1.6rem;
        position: relative;
        left: 0;

        &.overflow:not(.course) {
            left: -85vw;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    color: $color-primary;
}

h1 {
    font-size: 3.9rem;
    line-height: 4.7rem;

    @include breakpoint('md-down') {
        font-size: 2.5rem;
        line-height: 3.3rem;
    }
}

h2 {
    font-size: 2.8rem;
    line-height: 3.6rem;

    @include breakpoint('md-down') {
        font-size: 2.2rem;
        line-height: 3.0rem;
    }

    &.large {
        @extend h1;
    }
}

h3 {
    font-size: 1.9rem;
    line-height: 2.7rem;

    @include breakpoint('md-down') {
        font-size: 1.9rem;
        line-height: 2.7rem;
    }
}

h4 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2.5rem;
}

h5 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
}

a {
    @include transition;
    opacity: 1;
    color: $color-secondary;

    &:hover {
        text-decoration: none;
        color: $color-secondary-dark;
        // opacity: 0.5;
    }
}

address {
    p {
        margin-bottom: 0;
    }
}

* {
    -webkit-overflow-scrolling: touch;
}
