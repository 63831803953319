.code-activate {
    h2 {
        color: white;
        margin-bottom: 3rem;
    }

    form {
        width: 30rem;

        .button {
            width: 100%;
        }
    }
}
