.address {
	@include breakpoint('md-down') {
		// flex-direction: column-reverse;
	}

	.content {
		@include breakpoint('md-down') {
			text-align: center;
		}
	}

	.map {
		@include breakpoint('md-down') {
			margin-top: 5rem;
			margin-bottom: -5rem;
			padding: 0;
			height: 30rem;
		}
	}
}