.contact-list {
    list-style: none;
    padding: 0;

    li {
        font-size: 1.7rem;
        margin-bottom: 1rem;

        @include breakpoint('md-down') {
            background: white;
            margin-bottom: 0;
            padding: 1.5rem;
            display: flex;
            align-items: center;

            &:first-child {
                background: $color-gray;
            }
        }

        i {
            text-align: center;
            margin-right: 1rem;

            &:before {
                background-color: $color-tertiary;

                @include breakpoint('md-down') {
                    background-color: $color-secondary;
                }
            }
        }

        a {
            color: white;

            @include breakpoint('md-down') {
                color: black;
                white-space: nowrap;
                overflow: hidden;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-gray-darker;
                text-decoration: none;
            }
        }
    }

    &-boxed {
        list-style: none;
        padding: 0;

        li {
            @extend h2;
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            @include breakpoint('md-down') {
                font-size: 1.9rem;
                line-height: 2.7rem;

            }

            i {
                width: 5rem !important;
                height: 5rem !important;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1rem;
                background: $color-secondary;
                flex-shrink: 0;

                &:before {
                    width: 1em !important;
                    height: 1em !important;
                    background-color: white;
                    position: relative !important;
                }
            }

            a {
                color: $color-primary;                    

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: $color-primary-dark;
                }
            }
        }
    }
}


.course-chapters {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 2px;
        position: relative;

        a {
            display: block;
            background: $color-gray;
            padding: 1.25rem;
            color: $color-primary;

            &:hover,
            &:focus {
                background: $color-gray-dark;
                outline: none;
            }
        }

        &:after {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            content: '';
            top: 1.4rem;
            right: 1.25rem;
            width: 2.2rem;
            height: 2.2rem;
            color: white;
            background: white;
            border-radius: 50%;
            border: 2px solid $color-gray-dark;
        }

        &.complete {
            a {
                color: $color-gray-darker;
            }

            &:after {
                background: $color-secondary;
                border-color: $color-secondary;
                content: url('../images/icons/tick.svg');
            }

            & + li:not(.complete):after {
                border-color: $color-secondary;
            }
        }
    }
}


.menu-list {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 2rem;
        position: relative;

        a {
            display: block;
            background: $color-gray;
            padding: 1.25rem;
            padding-right: 4.25rem;
            color: $color-primary;

            &:hover,
            &:focus {
                background: $color-gray-dark;
                outline: none;
            }
        }

        &:after {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            content: '';
            mask: url('../images/icons/chevron-right.svg') no-repeat 50% 50%;
            top: calc(50% - 1.1rem);
            right: 1.25rem;
            width: 2.2rem;
            height: 2.2rem;
            color: $color-secondary;
            background: $color-secondary;
        }
    }
}

