form {
	// display: flex;
	// flex-direction: column;
	// align-items: flex-end;

	.form-control {
		border-radius: 0;
		margin-bottom: 1.5rem;
		padding: 1rem 1.5rem;
        // font-weight: bold;
		font-size: 1.5rem;
		min-height: 60px;
		border: 3px solid $color-gray-dark;
        color: black;

	    @include breakpoint('md-down') {
				min-height: 60px;
	    }

		&::placeholder {
			color: black;
		}

		&:focus {
			outline: none;
			box-shadow: none;
			border: 3px solid $color-secondary;
		}
	}

	select.form-control {
		text-align: left;
		text-align-last: left;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='18' viewBox='0 0 24 24'><path fill='grey' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") #fff;
		background-position: 98% 50%;
		background-repeat: no-repeat;
	}

	label {
		width: 100%;

		.form-control {
			display: inline-block;
			width: auto;
			margin-right: 1rem;
		}
	}

    .dark &,
	&.dark {
		.form-control {
			background-color: $color-gray;
		}
        .checkbox {
            color: black;
        }
	}

	input[type=submit] {
	    @include breakpoint('sm-down') {
    		width: 90%;
    		margin: 0 auto;
    		max-width: 230px;
	    }
	}
}

.checkbox {
	display: block;
	position: relative;
	padding-left: 4rem;
	margin-bottom: 1rem;
	cursor: pointer;
	user-select: none;
    color: black;

	input {
		position: absolute;
		cursor: pointer;
        visibility: hidden;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            content: '';
            position: absolute;
            top: 5px;
            left: -4rem;
            height: 2.5rem;
            width: 2.5rem;
            background-color: transparent;
            border: 2px solid $color-tertiary;
            border-radius: 50%;
            visibility: visible;
        }

        &:focus:before,
        &:hover:before,
		&:checked:before {
			background-color: $color-tertiary;
            content: url('../images/icons/tick.svg');
		}

        &:focus:before,
        &:hover:before {
			border-color: $color-tertiary-dark;
		}
	}

	label {
        cursor: pointer;
    }

	.dark & {
		input {
            &:before {
                border: 2px solid $color-tertiary;
            }

            &:hover:before,
            &:checked:before {
                background-color: $color-tertiary;
            }
		}
	}
}


.login {
	.button {
		@include breakpoint('md-down') {
			width: 75%;
			max-width: 100%;

			&:last-child {
				margin-top: 1.5rem;
			}
		}
	}
}