header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 4.0rem 0;
    position: relative;
    min-height: 100vh;

    @include breakpoint('md-down') {
        padding-top: 11rem;
        padding-bottom: 10rem;
    }

    &.home {
        &:after {
            display: none;
        }
    }

    &:before {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        // z-index: -2;
        background-image: url('../images/header-foot.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &:after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        background: black;
        opacity: 0.25
    }

    &.space {
        &:before {
            background-image: url('../images/Cosmo.jpg');
        }
        &:after {
            display: none;
        }
    }

    @include breakpoint('lg-up') {
        padding: 24.0rem 0;
    }

    > div {
        z-index: 2;
    }

    h1 {
        color: white;
    }

    &.spacer {
        padding: 12rem 0;
        min-height: auto;
        background-color: transparent;

        &:before,
        &:after {
            display: none;
        }

        @include bkgd-colors;

        @include breakpoint('md-down') {
            padding-top: 0rem;
        }
    }

    .details {
        padding: 3rem;
        background: rgba(255, 255, 255, 0.1);

        .info {
            h5 {
                color: white;
            }

            p {
                @extend h2;
                color: $color-tertiary;
            }

            & + .info {
                margin-top: 3rem;
            }
        }
    }
}

.scroll-icon {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    border-radius: 50%;
    border: 3px solid #ddd;
    display: flex;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    color: #ddd;
    z-index: 555;
    opacity: 1;
    @include transition;

    &.scroll {
        opacity: 0;
    }
}
