footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
    border-bottom: 9px solid $color-tertiary;

	.sponsers {
		img {
			max-height: 80px;
			max-width: 130px;
			margin-right: 1.5rem;

			@include breakpoint('md-down') {
				width: 50%;
				object-fit: contain;
				margin-bottom: 3rem;
				margin-right: 0;
			}	    	

			&:last-child {
				margin-right: 0;
			}
		}
	}

	h4 {
	    color: $color-tertiary;
	}

    p {
        margin: 0;
    }
}

.sponsers {
	text-align: center;

	img {
		max-height: 110px;
		max-width: 180px;
		margin-right: 3rem;

		&:last-child {
			margin-right: 0;
		}

		@include breakpoint('md-down') {
			max-width: 80%;
			object-fit: contain;
			margin-right: 0;
		}	    	
	}

	> .col-6 {
		@include breakpoint('md-down') {
			margin-bottom: 3rem;

			&:last-child,
			&:nth-last-child(2) {
				margin-bottom: 0;
			}
		}	    	
	}
}
