.slant {
    height: 22.0rem;
    width: 100%;
    position: absolute;
    @include transition;

    @include breakpoint('md-down') {
    	height: 11rem;
    }

    &:before,
    &:after {
    	display: block;
	    content: '';
	    height: 100%;
	    width: 100%;
	    position: absolute;

	    nav & {
	    	@include transition;
	    }
	}

    &-short {
        height: 11rem;
    }

	&-top {
		&-left {
			top: 0;
			&:before {
				z-index: 1;
				-webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
				        clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
			}

			&-primary:before {
			    background: $color-primary;
			}
			&-primary-light:before {
			    background: $color-primary-light;
			}
			&-primary-dark:before {
			    background: $color-primary-dark;
			}

			&-secondary:before {
			    background: $color-secondary;
			}
			&-secondary-light:before {
			    background: $color-secondary-light;
			}
			&-secondary-dark:before {
			    background: $color-secondary-dark;
			}

			&-tertiary:before {
			    background: $color-tertiary;
			}
			&-tertiary-light:before {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:before {
			    background: $color-tertiary-dark;
			}

			&-gray:before {
			    background: $color-gray;
			}
			&-gray-light:before {
			    background: $color-gray-light;
			}
			&-gray-dark:before {
			    background: $color-gray-dark;
			}
			&-gray-darker:before {
			    background: $color-gray-darker;
			}

			&-white:before {
			    background: white;
			}
			&-black:before {
			    background: black;
			}
		}

		&-right {
			top: 0;
			&:after {
				z-index: 2;
				-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
				        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
			}

			&-primary:after {
			    background: $color-primary;
			}
			&-primary-light:after {
			    background: $color-primary-light;
			}
			&-primary-dark:after {
			    background: $color-primary-dark;
			}

			&-secondary:after {
			    background: $color-secondary;
			}
			&-secondary-light:after {
			    background: $color-secondary-light;
			}
			&-secondary-dark:after {
			    background: $color-secondary-dark;
			}

			&-tertiary:after {
			    background: $color-tertiary;
			}
			&-tertiary-light:after {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:after {
			    background: $color-tertiary-dark;
			}

			&-gray:after {
			    background: $color-gray;
			}
			&-gray-light:after {
			    background: $color-gray-light;
			}
			&-gray-dark:after {
			    background: $color-gray-dark;
			}
			&-gray-darker:after {
			    background: $color-gray-darker;
			}

			&-white:after {
			    background: white;
			}
			&-black:after {
			    background: black;
			}
		}
	}

	&-bottom {
		&-left {
			bottom: -1px;
			&:before {
				z-index: 2;
				-webkit-clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
				        clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
			}

			&-primary:before {
			    background: $color-primary;
			}
			&-primary-light:before {
			    background: $color-primary-light;
			}
			&-primary-dark:before {
			    background: $color-primary-dark;
			}

			&-secondary:before {
			    background: $color-secondary;
			}
			&-secondary-light:before {
			    background: $color-secondary-light;
			}
			&-secondary-dark:before {
			    background: $color-secondary-dark;
			}

			&-tertiary:before {
			    background: $color-tertiary;
			}
			&-tertiary-light:before {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:before {
			    background: $color-tertiary-dark;
			}

			&-gray:before {
			    background: $color-gray;
			}
			&-gray-light:before {
			    background: $color-gray-light;
			}
			&-gray-dark:before {
			    background: $color-gray-dark;
			}
			&-gray-darker:before {
			    background: $color-gray-darker;
			}

			&-white:before {
			    background: white;
			}
			&-black:before {
			    background: black;
			}
		}

		&-right {
			bottom: -1px;
			&:after {
				z-index: 1;
				-webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
				        clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
			}

			&-primary:after {
			    background: $color-primary;
			}
			&-primary-light:after {
			    background: $color-primary-light;
			}
			&-primary-dark:after {
			    background: $color-primary-dark;
			}

			&-secondary:after {
			    background: $color-secondary;
			}
			&-secondary-light:after {
			    background: $color-secondary-light;
			}
			&-secondary-dark:after {
			    background: $color-secondary-dark;
			}

			&-tertiary:after {
			    background: $color-tertiary;
			}
			&-tertiary-light:after {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:after {
			    background: $color-tertiary-dark;
			}

			&-gray:after {
			    background: $color-gray;
			}
			&-gray-light:after {
			    background: $color-gray-light;
			}
			&-gray-dark:after {
			    background: $color-gray-dark;
			}
			&-gray-darker:after {
			    background: $color-gray-darker;
			}

			&-white:after {
			    background: white;
			}
			&-black:after {
			    background: black;
			}
		}
	}
}

.box-slant {
	padding: 6.0rem 0;
	position: relative;

	@include breakpoint('md-down') {
		padding: 3rem 0;
	}

    &:before,
    &:after {
    	display: block;
	    content: '';
	    height: 100%;
	    width: 100%;
	    position: absolute;
	}

	&-top {
		&-left {
			&:before {
				z-index: 1;
				top: 0;
				-webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 5.5rem);
				        clip-path: polygon(0 0, 100% 0, 100% 0, 0 5.5rem);

				@include breakpoint('md-down') {
					-webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 2.5rem);
							clip-path: polygon(0 0, 100% 0, 100% 0, 0 2.5rem);
				}
			}

			&-primary:before {
			    background: $color-primary;
			}
			&-primary-light:before {
			    background: $color-primary-light;
			}
			&-primary-dark:before {
			    background: $color-primary-dark;
			}

			&-secondary:before {
			    background: $color-secondary;
			}
			&-secondary-light:before {
			    background: $color-secondary-light;
			}
			&-secondary-dark:before {
			    background: $color-secondary-dark;
			}

			&-tertiary:before {
			    background: $color-tertiary;
			}
			&-tertiary-light:before {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:before {
			    background: $color-tertiary-dark;
			}

			&-gray:before {
			    background: $color-gray;
			}
			&-gray-light:before {
			    background: $color-gray-light;
			}
			&-gray-dark:before {
			    background: $color-gray-dark;
			}
			&-gray-darker:before {
			    background: $color-gray-darker;
			}

			&-white:before {
			    background: white;
			}
			&-black:before {
			    background: black;
			}
		}

		&-right {
			&:after {
				z-index: 2;
				top: 0;
				-webkit-clip-path: polygon(0 0, 100% 0, 100% 5.5rem, 0 0);
				        clip-path: polygon(0 0, 100% 0, 100% 5.5rem, 0 0);
				
				@include breakpoint('md-down') {
					-webkit-clip-path: polygon(0 0, 100% 0, 100% 2.5rem, 0 0);
							clip-path: polygon(0 0, 100% 0, 100% 2.5rem, 0 0);
				}
			}

			&-primary:after {
			    background: $color-primary;
			}
			&-primary-light:after {
			    background: $color-primary-light;
			}
			&-primary-dark:after {
			    background: $color-primary-dark;
			}

			&-secondary:after {
			    background: $color-secondary;
			}
			&-secondary-light:after {
			    background: $color-secondary-light;
			}
			&-secondary-dark:after {
			    background: $color-secondary-dark;
			}

			&-tertiary:after {
			    background: $color-tertiary;
			}
			&-tertiary-light:after {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:after {
			    background: $color-tertiary-dark;
			}

			&-gray:after {
			    background: $color-gray;
			}
			&-gray-light:after {
			    background: $color-gray-light;
			}
			&-gray-dark:after {
			    background: $color-gray-dark;
			}
			&-gray-darker:after {
			    background: $color-gray-darker;
			}

			&-white:after {
			    background: white;
			}
			&-black:after {
			    background: black;
			}
		}
	}

	&-bottom {
		&-left {
			&:before {
				z-index: 2;
				bottom: 0;
				-webkit-clip-path: polygon(0 calc(100% - 5.5rem), 100% 100%, 100% 100%, 0 100%);
				        clip-path: polygon(0 calc(100% - 5.5rem), 100% 100%, 100% 100%, 0 100%);

				@include breakpoint('md-down') {
					-webkit-clip-path: polygon(0 calc(100% - 2.5rem), 100% 100%, 100% 100%, 0 100%);
							clip-path: polygon(0 calc(100% - 2.5rem), 100% 100%, 100% 100%, 0 100%);
				}
			}

			&-primary:before {
			    background: $color-primary;
			}
			&-primary-light:before {
			    background: $color-primary-light;
			}
			&-primary-dark:before {
			    background: $color-primary-dark;
			}

			&-secondary:before {
			    background: $color-secondary;
			}
			&-secondary-light:before {
			    background: $color-secondary-light;
			}
			&-secondary-dark:before {
			    background: $color-secondary-dark;
			}

			&-tertiary:before {
			    background: $color-tertiary;
			}
			&-tertiary-light:before {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:before {
			    background: $color-tertiary-dark;
			}

			&-gray:before {
			    background: $color-gray;
			}
			&-gray-light:before {
			    background: $color-gray-light;
			}
			&-gray-dark:before {
			    background: $color-gray-dark;
			}
			&-gray-darker:before {
			    background: $color-gray-darker;
			}

			&-white:before {
			    background: white;
			}
			&-black:before {
			    background: black;
			}
		}

		&-right {
			&:after {
				z-index: 1;
				bottom: 0;
				-webkit-clip-path: polygon(0 100%, 100% calc(100% - 5.5rem), 100% 100%, 0 100%);
				        clip-path: polygon(0 100%, 100% calc(100% - 5.5rem), 100% 100%, 0 100%);

				@include breakpoint('md-down') {
					-webkit-clip-path: polygon(0 100%, 100% calc(100% - 2.5rem), 100% 100%, 0 100%);
							clip-path: polygon(0 100%, 100% calc(100% - 2.5rem), 100% 100%, 0 100%);
				}
			}

			&-primary:after {
			    background: $color-primary;
			}
			&-primary-light:after {
			    background: $color-primary-light;
			}
			&-primary-dark:after {
			    background: $color-primary-dark;
			}

			&-secondary:after {
			    background: $color-secondary;
			}
			&-secondary-light:after {
			    background: $color-secondary-light;
			}
			&-secondary-dark:after {
			    background: $color-secondary-dark;
			}

			&-tertiary:after {
			    background: $color-tertiary;
			}
			&-tertiary-light:after {
			    background: $color-tertiary-light;
			}
			&-tertiary-dark:after {
			    background: $color-tertiary-dark;
			}

			&-gray:after {
			    background: $color-gray;
			}
			&-gray-light:after {
			    background: $color-gray-light;
			}
			&-gray-dark:after {
			    background: $color-gray-dark;
			}
			&-gray-darker:after {
			    background: $color-gray-darker;
			}

			&-white:after {
			    background: white;
			}
			&-black:after {
			    background: black;
			}
		}
	}

	img {
		width: 100%;
		margin-top: -6rem;
		margin-bottom: -6rem;

		@include breakpoint('md-down') {
			margin-top: -3rem;
			margin-bottom: -3rem;
		}
	}
}
