.fa {
    &.via {
        &-bars {
            &:before {
                mask: url('../images/icons/bars.svg') no-repeat 50% 50%;
            }
        }

        &-bullet {
            &:before {
                mask: url('../images/icons/bullet.svg') no-repeat 50% 50%;
            }
        }

        &-cart {
            &:before {
                mask: url('../images/icons/cart.svg') no-repeat 50% 50%;
            }
        }

        &-chevron-up {
            &:before {
                mask: url('../images/icons/chevron-up.svg') no-repeat 50% 50%;
            }
        }

        &-chevron-down {
            &:before {
                mask: url('../images/icons/chevron-down.svg') no-repeat 50% 50%;
            }
        }

        &-chevron-left {
            &:before {
                mask: url('../images/icons/chevron-left.svg') no-repeat 50% 50%;
            }
        }

        &-chevron-right {
            &:before {
                mask: url('../images/icons/chevron-right.svg') no-repeat 50% 50%;
            }
        }

        &-cross {
            &:before {
                mask: url('../images/icons/cross.svg') no-repeat 50% 50%;
            }
        }

        &-email {
            &:before {
                mask: url('../images/icons/email.svg') no-repeat 50% 50%;
            }
        }

        &-quote {
            &:before {
                mask: url('../images/icons/quote.svg') no-repeat 50% 50%;
            }
        }

        &-map {
            &:before {
                mask: url('../images/icons/map.svg') no-repeat 50% 50%;
            }
        }

        &-map-outline {
            &:before {
                mask: url('../images/icons/map-outline.svg') no-repeat 50% 50%;
            }
        }

        &-plus {
            &:before {
                mask: url('../images/icons/plus.svg') no-repeat 50% 50%;
            }
        }

        &-minus {
            &:before {
                mask: url('../images/icons/minus.svg') no-repeat 50% 50%;
            }
        }

        &-phone {
            &:before {
                mask: url('../images/icons/phone.svg') no-repeat 50% 50%;
            }
        }

        &-reset {
            &:before {
                mask: url('../images/icons/reset.svg') no-repeat 50% 50%;
            }
        }

        &-search {
            &:before {
                mask: url('../images/icons/search.svg') no-repeat 50% 50%;
            }
        }

        &-tick {
            &:before {
                mask: url('../images/icons/tick.svg') no-repeat 50% 50%;
            }
        }
    }

    &[class*="via-"] {
        position: relative;
        width: 1.2em;
        height: 1.2em;
        flex-shrink: 0;

        &:before {
            width: 1.2em;
            height: 1.2em;
            display: inline-block;
            content: '';
            mask-size: contain;
            position: absolute;
            // bottom: -0.3em;
            left: 0;
            max-width: 100%;
            max-height: 100%;
        }

        @include bkgd-colors-before;
    }
}
