.video-background {
    background: #000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    // iframe {
    //     transform: scale(1.5);
    // }

    // @include breakpoint('max-xs') {
    //     transform: scale(1.5);
    // }

    // &:before {
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     content:  '';
    //     width: 100%;
    //     height: 100%;
    //     z-index: 1;
    //     background: rgba(6, 41, 102, 0.7);
    // }
}

.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#vidtop-content {
    top: 0;
}

@media (min-aspect-ratio: 16/9) {
    .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
    .video-foreground { width: 300%; left: -100%; }
}
