.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 3px solid transparent;
	border-radius: 0;
	padding: 0 3rem;
	height: 6.0rem;
	cursor: pointer;
	text-decoration: none;
	color: #FFF;
	font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    min-width: 12.0rem;
	@include transition;

	@include breakpoint('md-down') {
		height: 5.5rem;
		min-width: 10rem;
	}

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		color: #FFF;
	}

	&-small {
		height: 5.5rem;
	}

    &.tiny-block {
        width: 3rem;
        height: 3rem;
        padding: 0;
        min-width: 0;
        z-index: 5;
    }

    &.thin {
        padding: 0 1rem;
    }

	&.wide {
		min-width: 21rem;
	}

	&--primary {
		background: $color-primary;
		border-color: $color-primary;

		&:hover,
		&:focus,
		&:active {
			background: $color-primary-dark;
			border-color: $color-primary-dark;
		}

		&-outline {
			background: transparent;
			border-color: $color-primary;
			color: inherit;

			&:hover,
			&:focus,
			&:active {
				background: $color-primary;
			}

			&.black {
				&:hover {
					color: white !important;
				}
			}
		}

		&-light {
			background: $color-primary-light;
			border-color: $color-primary-light;

			&:hover,
			&:focus,
			&:active {
				background: $color-primary;
				border-color: $color-primary;
			}

			&-outline {
				background: transparent;
				border-color: $color-primary-light;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-primary-light;
				}

				&.black {
					&:hover {
						color: white !important;
					}
				}
			}
		}

		&-dark {
			background: $color-primary-dark;
			border-color: $color-primary-dark;

			&:hover,
			&:focus,
			&:active {
				background: $color-primary;
				border-color: $color-primary;
			}

			&-outline {
				background: transparent;
				border-color: $color-primary-dark;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-primary-dark;
				}

				&.black {
					&:hover,
					&:focus,
					&:active {
						color: white !important;
					}
				}
			}
		}
	}

	&--secondary {
		background: $color-secondary;
		border-color: $color-secondary;

		&:hover,
		&:focus,
		&:active {
			background: $color-secondary-dark;
			border-color: $color-secondary-dark;
		}

		&-outline {
			background: transparent;
			border-color: $color-secondary;
			color: inherit;

			&:hover,
			&:focus,
			&:active {
				background: $color-secondary;
			}

			&.black {
				&:hover,
				&:focus,
				&:active {
					color: white !important;
				}
			}
		}

		&-light {
			background: $color-secondary-light;
			border-color: $color-secondary-light;

			&:hover,
			&:focus,
			&:active {
				background: $color-secondary;
				border-color: $color-secondary;
			}

			&-outline {
				background: transparent;
				border-color: $color-secondary-light;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-secondary-light;
				}

				&.black {
					&:hover,
					&:focus,
					&:active {
						color: white !important;
					}
				}
			}
		}

		&-dark {
			background: $color-secondary-dark;
			border-color: $color-secondary-dark;

			&:hover,
			&:focus,
			&:active {
				background: $color-secondary;
				border-color: $color-secondary;
			}

			&-outline {
				background: transparent;
				border-color: $color-secondary-dark;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-secondary-dark;
				}

				&.black {
					&:hover,
					&:focus,
					&:active {
						color: white !important;
					}
				}
			}
		}
	}

	&--tertiary {
		background: $color-tertiary;
		border-color: $color-tertiary;

		&:hover,
		&:focus,
		&:active {
			background: $color-tertiary-dark;
			border-color: $color-tertiary-dark;
		}

		&-outline {
			background: transparent;
			border-color: $color-tertiary;
			color: inherit;

			&:hover,
			&:focus,
			&:active {
				background: $color-tertiary-light;
			}

			&.black {
				&:hover,
				&:focus,
				&:active {
					color: white !important;
				}
			}
		}

		&-light {
			background: $color-tertiary-light;
			border-color: $color-tertiary-light;

			&:hover,
			&:focus,
			&:active {
				background: $color-tertiary;
				border-color: $color-tertiary;
			}

			&-outline {
				background: transparent;
				border-color: $color-tertiary-light;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-tertiary-light;
				}

				&.black {
					&:hover,
					&:focus,
					&:active {
						color: white !important;
					}
				}
			}
		}

		&-dark {
			background: $color-tertiary-dark;
			border-color: $color-tertiary-dark;

			&:hover,
			&:focus,
			&:active {
				background: $color-tertiary;
				border-color: $color-tertiary;
			}

			&-outline {
				background: transparent;
				border-color: $color-tertiary-dark;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-tertiary;
				}

				&.black {
					&:hover,
					&:focus,
					&:active {
						color: white !important;
					}
				}
			}
		}
	}

	&--gray {
		background: $color-gray;
		border-color: $color-gray;

		&:hover,
		&:focus,
		&:active {
			background: $color-gray-dark;
			border-color: $color-gray-dark;
		}

		&-outline {
			background: transparent;
			border-color: $color-gray;
			color: inherit;

			&:hover,
			&:focus,
			&:active {
				background: $color-gray-dark;
			}

			&.black {
				&:hover,
				&:focus,
				&:active {
					color: white !important;
				}
			}
		}

		&-light {
			background: $color-gray-light;
			border-color: $color-gray-light;

			&:hover,
			&:focus,
			&:active {
				background: $color-gray;
				border-color: $color-gray;
			}

			&-outline {
				background: transparent;
				border-color: $color-gray-light;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-gray-light;
				}

				&.black {
					&:hover,
					&:focus,
					&:active {
						color: white !important;
					}
				}
			}
		}

		&-dark {
			background: $color-gray-dark;
			border-color: $color-gray-dark;

			&:hover,
			&:focus,
			&:active {
				background: $color-gray;
				border-color: $color-gray;
			}

			&-outline {
				background: transparent;
				border-color: $color-gray-dark;
				color: inherit;

				&:hover,
				&:focus,
				&:active {
					background: $color-gray;
				}

				&.black {
					&:hover,
					&:focus,
					&:active {
						color: white !important;
					}
				}
			}
		}
	}

    &--white {
        background: white;
        border-color: white;
        color: black;

        &:hover,
        &:focus,
        &:active {
            background: $color-gray;
            border-color: $color-gray;
            color: black;
        }

        &-outline {
            background: transparent;
            border-color: white;

            &:hover,
            &:focus,
            &:active {
                border-color: $color-gray;
                background: $color-gray;
                color: black;
            }
        }
    }

    &--black {
        background: black;
        border-color: black;
        color: white;

        &:hover,
        &:focus,
        &:active {
            background: $color-primary-dark;
            border-color: $color-primary-dark;
            color: white;
        }

        &-outline {
            background: transparent;
            border-color: black;

            &:hover,
            &:focus,
            &:active {
                border-color: $color-primary-dark;
                background: $color-primary-dark;
                color: white;
            }
        }
    }

    &--transparent {
        background: transparent;
        border-color: transparent;
        color: black;

        &:hover,
        &:focus,
        &:active {
            background: $color-gray-light;
            border-color: $color-gray-light;
            color: black;
        }

        &.white {
            &:hover,
            &:focus,
            &:active {
                color: black !important;
            }
        }

        &-dark {
            &:hover,
            &:focus,
            &:active {
                border-color: $color-primary-dark;
                background: $color-primary-dark;
                color: white;
            }
        }
    }
}
