.history {

    &:before {
        content: '';
        display: block;
        height: 96%;
        position: absolute;
        top: 2%;
        left: 50%;
        margin-left: -5px;
        border-left: 1rem dotted $color-primary-light;
        z-index: 0;
    }

    > div {
        &:not(:first-child) {
            margin-top: -12rem;

            @include breakpoint('md-down') {
                margin-top: 5rem;
            }
        }

        &:first-child:before,
        &:last-child:before {
            content: '';
            display: block;
            position: absolute;
            left: calc(100% - 25px);
            background: $color-primary;
            width: 50px;
            height: calc(50% + 3rem);
            z-index: 1;

            @include breakpoint('md-down') {
                display: none;
            }
        }

        &:first-child:before {
            top: -2rem;
        }

        &:last-child:before {
            bottom: -5rem;
            left: auto;
            right: -25px;
        }
    }

    .left,
    .right {
        .box-slant {
            min-height: 300px;
        }

        .card {
            &:before {
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                content: '';
                position: absolute;
                top: calc(50% - 10px);
            }

            &:after {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border-width: 20px 0 20px 25.0px;
                background: $color-secondary;
                content: '';
                position: absolute;
                top: 50%;
                z-index: 1;
            }

            @include breakpoint('md-down') {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }

    .left {
        .card {
            &:before {
                left: 100%;
                border-width: 20px 0 20px 25.0px;
                border-color: transparent transparent transparent white;
            }

            &:after {
                left: calc(100% + 55px);
            }
        }
    }

    .right {
        margin-left: 50%;

        @include breakpoint('md-down') {
            margin-left: 0;
        }

        .card {
            &:before {
                right: 100%;
                border-width: 20px 25.0px 20px 0;
                border-color: transparent white transparent transparent;

            }

            &:after {
                right: calc(100% + 55px);
            }

        }
    }

    .timeline {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;

        &:after {
            content: '';
            position: absolute;
            width: 6px;
            top: 0;
            bottom: 0;
            left: 50%;
            margin-left: -3px;
        }
    }
}
