// Transition
@mixin transition {
    -webkit-transition: 0.5s ease all;
    -moz-transition: 0.5s ease all;
    -ms-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
	transition: 0.5s ease all;
}


// Text Colours
@mixin colors {
	&.primary {
		color: $color-primary !important;

		&-light {
			color: $color-primary-light !important;
		}

		&-dark {
			color: $color-primary-dark !important;
		}
	}

	&.secondary {
		color: $color-secondary !important;

		&-light {
			color: $color-secondary-light !important;
		}

		&-dark {
			color: $color-secondary-dark !important;
		}
	}

	&.tertiary {
		color: $color-tertiary !important;

		&-light {
			color: $color-tertiary-light !important;
		}

		&-dark {
			color: $color-tertiary-dark !important;
		}
	}

	&.gray {
		color: $color-gray !important;

		&-light {
			color: $color-gray-light !important;
		}

		&-dark {
			color: $color-gray-dark !important;
		}

		&-darker {
			color: $color-gray-darker !important;
		}
	}

	&.white {
		color: white !important;
	}

	&.black {
		color: black !important;
	}
}


// Background Colours
@mixin bkgd-colors {
	&.primary {
		background-color: $color-primary !important;

		&-light {
			background-color: $color-primary-light !important;
		}

		&-dark {
			background-color: $color-primary-dark !important;
		}
	}

	&.secondary {
		background-color: $color-secondary !important;

		&-light {
			background-color: $color-secondary-light !important;
		}

		&-dark {
			background-color: $color-secondary-dark !important;
		}
	}

	&.tertiary {
		background-color: $color-tertiary !important;

		&-light {
			background-color: $color-tertiary-light !important;
		}

		&-dark {
			background-color: $color-tertiary-dark !important;
		}
	}

	&.gray {
		background-color: $color-gray !important;

		&-light {
			background-color: $color-gray-light !important;
		}

		&-dark {
			background-color: $color-gray-dark !important;
		}

		&-darker {
			background-color: $color-gray-darker !important;
		}
	}

	&.white {
		background-color: white !important;
	}

	&.black {
		background-color: black !important;
	}
}


// Background Colours :Before;
@mixin bkgd-colors-before {
    &.primary {
        &:before {
            background-color: $color-primary;
        }

        &-light {
            &:before {
                background-color: $color-primary-light;
            }
        }

        &-dark {
            &:before {
                background-color: $color-primary-dark;
            }
        }
    }

    &.secondary {
        &:before {
            background-color: $color-secondary;
        }

        &-light {
            &:before {
                background-color: $color-secondary-light;
            }
        }

        &-dark {
            &:before {
                background-color: $color-secondary-dark;
            }
        }
    }

    &.tertiary {
        &:before {
            background-color: $color-tertiary;
        }

        &-light {
            &:before {
                background-color: $color-tertiary-light;
            }
        }

        &-dark {
            &:before {
                background-color: $color-tertiary-dark;
            }
        }
    }

    &.gray {
        &:before {
            background-color: $color-gray;
        }

        &-light {
            &:before {
                background-color: $color-gray-light;
            }
        }

        &-dark {
            &:before {
                background-color: $color-gray-dark;
            }
        }

        &-darker {
            &:before {
                background-color: $color-gray-darker;
            }
        }
    }

    &.black {
        &:before {
            background-color: black;
        }
    }

    &.white {
        &:before {
            background-color: white;
        }
    }
}


// Background Colours :After;
@mixin bkgd-colors-after {
    &.primary {
        &:after {
            background-color: $color-primary;
        }

        &-light {
            &:after {
                background-color: $color-primary-light;
            }
        }

        &-dark {
            &:after {
                background-color: $color-primary-dark;
            }
        }
    }

    &.secondary {
        &:after {
            background-color: $color-secondary;
        }

        &-light {
            &:after {
                background-color: $color-secondary-light;
            }
        }

        &-dark {
            &:after {
                background-color: $color-secondary-dark;
            }
        }
    }

    &.tertiary {
        &:after {
            background-color: $color-tertiary;
        }

        &-light {
            &:after {
                background-color: $color-tertiary-light;
            }
        }

        &-dark {
            &:after {
                background-color: $color-tertiary-dark;
            }
        }
    }

    &.gray {
        &:after {
            background-color: $color-gray;
        }

        &-light {
            &:after {
                background-color: $color-gray-light;
            }
        }

        &-dark {
            &:after {
                background-color: $color-gray-dark;
            }
        }

        &-darker {
            &:after {
                background-color: $color-gray-darker;
            }
        }
    }

    &.black {
        &:after {
            background-color: black;
        }
    }

    &.white {
        &:after {
            background-color: white;
        }
    }
}
