.feedback-slider {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.slick-slide:focus {
    outline: none;
}

.feedback {
    height: 100%;
    padding: 3rem;
    background: $color-primary;
    color: white;
    position: relative;
    margin-bottom: 5rem;
    min-height: 30.0rem;

    &:after {
        display: block;
        content: '';
        width: 50px;
        height: 50px;
        background: $color-primary;
        position: absolute;
        bottom: -50px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    }

    .via-quote {
        width: 3rem !important;
        height: 3rem !important;
        margin-bottom: 2.5rem;

        &:before {
            width: 3rem !important;
            height: 3rem !important;
        }
    }
}
